import { Flex, Button } from "@chakra-ui/react"
import { Heading } from "../../../components/Heading.component"
import { Text } from "../../../components/Text.component"

type TermsOfServiceProps = {
  setTerms: (type: boolean) => void
  incrementProgress: () => void
}

export const TermsOfService = ({ setTerms, incrementProgress }: TermsOfServiceProps) => {
  const submit = () => {
    incrementProgress()
    setTerms(true)
  }

  return (
    <Flex direction={"column"}>
      <Heading type="h2" pb={"1.6rem"} pt={"3.2rem"}>
        TERMS AND CONDITIONS OF SERVICE
      </Heading>
      <Text type={"b2"}>Last Updated: April _, 2023</Text>
      <br />
      <Text type={"b2"}>
        PLEASE READ THESE TERMS AND CONDITIONS OF SERVICE CAREFULLY: These Terms and Conditions of
        Service (“these Terms”) constitute a legal agreement between Entrega (together with its
        affiliates, “Entrega,” “we,” or “us”) and users of our services (“you” or “user”) governing
        the use and operation of Entrega’s websites, mobile applications, devises, or other
        application programming interfaces (collectively “technology platform”).
      </Text>
      <br />
      <Text type={"b2Bold"}>
        We have a Privacy Policy that is hereby incorporated herein and that shall constitute a part
        of these Terms.
      </Text>
      <br />
      <Text type={"b2Bold"}>
        These Terms require you and us to resolve some claims that you and we have against each
        other through submission of those claims to binding and final arbitration on an individual
        basis, rather than through jury trials or class actions, as set forth further below in these
        Terms.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        DESCRIPTION OF SERVICES
      </Text>
      <Text type={"b2"}>
        Entrega uses the technology platform to present users with a virtual storefront from which
        you can choose to purchase from a selection of construction related products available to be
        delivered to you or your jobsite through independent contractors or third-party delivery
        services (both “third-party service providers”). We stock our virtual store through a
        combination of goods that we hold in our inventory for sale and goods available to us
        through authorized distributors. We are the seller of the goods and the stated purchase
        price, including any applicable sales tax, are payable by you to us through the payment
        processing tools that are a part of the technology platform. We also serve as collection
        agent for the third-party service providers’ applicable fees, taxes, and any tips through
        the same payment processing tools. Title to the goods purchased shall pass from us to you
        upon delivery by us to the third-party providers for delivery. The third-party providers are
        providing both the delivery services and the vehicles and equipment to accomplish the same.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        PRICING FOR GOODS AND SERVICES
      </Text>
      <Text type={"b2"}>
        Entrega sets the prices in the virtual store front and those prices are subject to change
        from time to time. Pricing for the delivery services shall vary based upon the terms offered
        by available third-party providers and may be affected by demand, time, distance, weight,
        dimensions, handling requirements, weather, and other factors, including fuel prices. Your
        payment processing may include a temporary authorization for an additional amount to cover
        any possible variable cost services, weight adjustments, special requests, or any tips you
        may choose to add. We reserve the right to correct any errors, omissions, or inaccuracies
        with respect to pricing, availability, or product or service descriptions and the right to
        cancel any orders placed based upon any such errors, omissions, or inaccuracies (with a
        credit granted for any amounts already charged).
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        LIMITED RIGHTS GRANTED FOR USE OF SERVICES, PROHIBITED ACTIVITIES
      </Text>
      <Text type={"b2"}>
        Entrega grants you a non-exclusive, non-transferable, revocable and limited right to use the
        services provided through the technology platform we provide for their intended purpose
        subject to terms and conditions of these Terms and for no other purpose. You agree not copy,
        modify, disclose, distribute, compile, deliver, deep-link to, extract, scrape, index, frame,
        monitor, interfere with, probe/test or otherwise scan or circumvent any security or
        authentication features, or disrupt any part of the services provided or the content or the
        technology of the technology platform. You also agree not to try to extract source code or
        to reverse engineer any features or functionality of the services. You agree to only use the
        services offered for lawful purposes, in accordance with all applicable laws (including
        intellectual property, property, and publicity laws and rights), in accordance with these
        Terms and the instructions provided as part of the technology platform provided by us. If
        any portion of our services are provided with the use of an open-source license, that
        portion is made available to you in accordance with the open source license to the extent
        such license differs from the license otherwise granted herein.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        CONDITIONS OF USE
      </Text>
      <Text type={"b2"}>
        Entrega is available for use only by businesses (including sole proprietorships) and
        non-profit organization operating in an area in which we provide service. You may be
        required to establish an account to use the services we provide. You and the representative
        of your business or non-profit establishing an account represent and warrant that the
        representatives providing the information to establish and utilize the account are
        authorized to do so. You agree to be bound by these Terms and be responsible for the
        activity transacted through the account you establish, including for payment of all amounts
        due. We reserve the right to close or restrict accounts; restrict, cancel, or decline
        orders; and cancel or restrict the use of special offers or incentives, as we may deem
        appropriate for any reason, including without limitation, failure to respect Entrega
        employees or third-party providers, suspected fraud, abuse, or violation of these Terms. Any
        data, pictures, feedback, or comments that you provide to us, as part of the use of the
        technology platform or responding to it use, shall be available for use by us without cost
        or restriction, including without limitation to perform or improve our services. You
        represent and warrant that any material submitted, posted, or otherwise transmitted using
        the technology platform does not violate any intellectual property, privacy, or
        confidentiality rights, does not contain any false, deceptive, defamatory, hateful,
        discriminatory, offensive, violent, threatening, or pornographic language or graphics, does
        not share or solicit personal, illegal, or financially sensitive information or graphics.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        COMMUNICATIONS
      </Text>
      <Text type={"b2"}>
        By opening an account or using the services you are agreeing to receive and accept
        communications from us, including without limitation via telephone, text, and email numbers
        and address provided to us. Some of these communications may be from automated sources or
        from third-party providers in conjunction with providing services requested by you. You will
        be provided the option of opting out of any promotional communications that we may send.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        NO GUARANTEE OR WARRANTY REGARDING PRODUCTS OR DESCRIPTIONS
      </Text>
      <Text type={"b2"}>
        Entrega is selling products manufactured by others and utilizing information from such
        manufacturers or their packaging to describe the products and their characteristics, number,
        and quality.
      </Text>
      <br />
      <Text type={"b2Bold"}>
        You agree that we assume no liability for the products sold or the descriptions,
        information, or representations or warranties stated based upon information made available
        from the manufacturers, distributors, or their packaging or product literature.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        DELIVERY SERVICES PROVIDED BY THIRD PARTIES, ENTREGA NOT LIABLE
      </Text>
      <Text type={"b2"}>
        Entrega’s technology platform facilitates the engagement of a third-party provider to
        deliver to you the products purchased. We are not in the delivery business; we are nor a
        common carrier. We do not guarantee and are not liable for the activities of the third-party
        providers offering delivery services through our platform. Title to goods shall pass to you
        from us upon collection of the goods by the third-party provider from us with respect to
        items we inventory or from our distribution sources for all other items.
      </Text>
      <br />
      <Text type={"b2Bold"}>
        You agree that we have no responsibility for or liability for the acts or omissions by any
        third-party provider and acknowledge that we have no obligation to assess or guarantee the
        suitability, legality, or ability of third-party providers offering delivery services.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        ENTREGA’S TECHNOLGY PLATFORM AND GOODS AND SERVICES ARE MADE AVAILABLE ON AN “AS AVAILABLE”
        AND “AS IS” BASIS; LIMITATION OF LIABILITY
      </Text>
      <Text type={"b2"}>
        We provide our technology platform and the goods and services provided for thereon on an “as
        available” and “as is” basis, and disclaim all representations and warranties, express,
        legal, implied, or statutory, including without limitation the implied warranties of
        merchantability, fitness for a particular purpose, title, and non-infringement. We make no
        guarantee that services will be uninterrupted or error free. You agree that the risk
        associated with the products offered and the delivery services made available from
        third-party providers remains solely with you. You agree that we will not be liable for any
        economic costs related to your use of the technology platform and the related goods and
        services.{" "}
      </Text>
      <br />
      <Text type={"b2Bold"}>
        In no event shall we or our officers, directors, employees, agents, or owners be liable to
        you for any incidental, special, punitive, consequential, or indirect damages (including
        without limitation damages caused by the use of the technology platform or its connection to
        your computer system or devices, by interruption of the services, or the cost of substitute
        services) however arising, including negligence, even if on notice of the possibility of
        such damages. In any case, we shall not be liable in an amount in excess of the greater of
        One Hundred Dollars ($100.00) or the amount spent by you over the six (6) months preceding
        the date of the most recent event that is a cause of the claims made. The above limitations
        shall not apply where specifically prohibited by applicable law to the extent so prohibited.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        INDEMNIFICATION
      </Text>
      <Text type={"b2"}>
        You agree to defend, indemnify and hold harmless Entrega, its officers, directors,
        employees, agents, and owners, (each, an "indemnified party") from and against any losses,
        claims, actions, costs, damages, penalties, fines and expenses, including without limitation
        attorneys’ and experts’ fees and expenses, that may be incurred by an indemnified party
        arising out of, relating to or resulting from: (i) unauthorized use of the services or any
        breach of these Terms by you, including without limitation any actual or alleged violation
        of any law, rule or regulation; (ii) any third party’s access or use of the technology
        platform using your account; or (iii) any dispute or issue between you and any third party,
        including without limitation any third-party provider.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        DISPUTE RESOLUTION, ARBITRATION PROVISIONS
      </Text>
      <Text type={"b2"}>
        For all disputes arising out of, or related to these Terms and the goods and services
        provided for hereunder, any claims that fall within the monetary jurisdiction of the Small
        Claims Court located closest to the location of the place for delivery of the goods subject
        to these Terms shall have exclusive jurisdiction over the dispute(s) and the Small Claims
        Court shall finally resolve such dispute(s), all of which shall be on an individual claim
        basis (not in a class action, or a collective, consolidated, or representative proceeding).{" "}
      </Text>
      <br />
      <Text type={"b2Bold"}>
        For all disputes that exceed the monetary jurisdiction of the Small Claims Court located
        closest to the location of the place for delivery of the goods subject to these Terms, you
        and we agree as follows: (i) you and we agree to negotiate in good faith in an attempt to
        resolve any dispute(s) for a period of at least thirty (30) days following receipt of
        written notice from either party to the other which shall set forth, in specifics, the
        nature and description of the dispute(s), the actions or inactions of the other party which
        caused the dispute(s), and the relief or remedy requested by the notifying party; and (ii)
        should you and we be unable to resolve the dispute(s) through good faith negotiations, any
        and all disputes shall, be decided by arbitration on an individual basis (not in a class
        action, or a collective, consolidated, or representative proceeding) in accordance with the
        Arbitration Rules of the American Arbitration Association then pertaining; such award(s)
        shall be final and binding on the parties with no right to appeal the arbitration award.
      </Text>
      <br />
      <Text type={"b2"}>
        For all dispute(s) whether resolved in Small Claims Court or Arbitration, the prevailing
        party shall be entitled to recover reasonable attorney fees and all costs and expenses
        incurred, including the costs to arbitrate and to engage expert consultants.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        WAIVER OF JURY TRIALS AND CLASS ACTION SUITS
      </Text>
      <Text type={"b2Bold"}>
        You agree to waive and not participate in any and all class action proceedings or
        counterclaims against us, whether at law or in equity, regardless of which party brings the
        claim, whether in Small Claims Court, through arbitration, or otherwise. This waiver shall
        apply to any matter whatsoever between the parties hereto which arises out of or is related
        in any way to these Terms and the goods and services provided for hereunder. You and we
        agree to waive any right to a trial by jury with respect to any claim involving these Terms
        or and the goods and services provided for hereunder.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        GENERAL PROVISIONS
      </Text>
      <Text type={"b2"}>
        You can stop using Entrega’s technology platform at any time and we may terminate access to
        you at any time. In the event of termination, the provisions of these Terms shall continue
        to apply with respect to matters arising out of the time before such termination. These
        Terms shall be governed by the law of the State of Ohio without respect to it conflicts of
        law principles. These Terms, as they may be amended from time to time, constitute the entire
        agreement between you and us with respect to the matters herein. If any provisions hereof
        are found by any court of competent jurisdiction or duly appointed arbitrator to be
        unenforceable, such provisions shall be limited to the minimum extent necessary to make them
        enforceable and the remaining provisions shall remain in full force and effect. If we fail
        to monitor or enforce a provision of these Terms, such failure shall not constitute a waiver
        of the right to monitor or enforce that provision at a later time or to enforce any other
        provision of these Terms or these Terms as a whole. You may not assign your rights,
        licenses, or obligations hereunder. We may assign our rights, licenses, or obligations
        hereunder without limitation. The headings in these Terms are provided for convenience only
        and shall not be interpreted to limit the terms of any paragraphs hereof.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        AGREEMENT TO THESE TERMS, AMENDMENTS THERETO
      </Text>
      <Text type={"b2"}>
        As a condition of the commencement of your use of Entrega’s technology platform, you will be
        asked to confirm your agreement to these Terms. We maintain the right to modify these Terms
        at any time. In the event of a modification, the modified version of these Terms shall be
        posted to our website and you will receive electronic notice of such posting. The
        modifications will not be retroactive. Any party continuing to use Entrega’s technology
        platform after the posting of such modifications shall be considered to have consented to
        the same and to have agreed to be bound thereby.
      </Text>
      <Text type={"b1Bold"} py={"2.4rem"}>
        COPYRIGHT AND TRADEMARK PROTECTION POLICY
      </Text>
      <Text type={"b2"}>
        To demonstrate respect for the intellectual property rights of others and to implement a
        copyright and trademark protection policy in accordance with the Digital Millenium Copyright
        Act, we will respond promptly to valid notices of copyright or trademark infringement. Users
        involved in infringement activity shall be subject to termination from the technology
        platform. Any content posted or made available on the technology platform which is believed
        to constitute infringement of your copyright or trademark rights should be brought to our
        attention by sending a written notice of infringement to Entrega’s designated Copyright and
        Trademark Agent using the contact information listed below. In your notice, please specify
        the nature of the copyright or trademark infringement and include the following information:
        (a) an electronic or physical signature of the owner of the copyright or trademark in
        question or a person authorized to act on behalf of the owner of the copyright or trademark;
        (b) a description of the claimed infringing material as well as identification of the
        claimed infringing material, including the location of such material on the technology
        platform (e.g., the URL of the claimed infringing material if applicable or other means by
        which we can locate the material); (c) complete contact information, including the name of
        the owner of the copyright or trademark and your name, title, address, telephone number, and
        email address; (d) a statement that you have a good faith belief that the disputed use is
        not authorized by the copyright or trademark owner, its agent, or the law; and (e) a
        statement, made under penalty of perjury, that the information provided in your notice is
        accurate and that you are the copyright or trademark owner or authorized to act on behalf of
        the owner.
      </Text>
      <br />
      <Text type={"b2"}>Entrega</Text>
      <Text type={"b2"}>ATTN: Copyright and Trademark Agent</Text>
      <Text type={"b2"}>____________________________</Text>
      <Text type={"b2"}>____________________________</Text>
      <Text type={"b2"}>_____@_____________________</Text>

      <Text type={"b1Bold"} py={"2.4rem"}>
        CONTACT INFORMATION
      </Text>
      <Text type={"b2"}>Questions or comments about these Terms may be addressed to:</Text>
      <Text type={"b2"}>____________________________</Text>
      <Text type={"b2"}>____________________________</Text>
      <Text type={"b2"}>_____@_____________________</Text>

      <Button
        my={"3.6rem"}
        w="100%"
        onClick={() => {
          submit()
        }}
      >
        I agree
      </Button>
    </Flex>
  )
}
