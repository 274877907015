import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom"
import { Splash } from "../pages/Splash/Splash.page"
import { CreateUser } from "../pages/UserCreation/CreateUser.page"
import { CreateBusiness } from "../pages/BusinessCreation/CreateBusiness.page"
import { Inventory } from "../pages/Inventory/Inventory.page"
import { OrderHistory } from "../pages/OrderHistory/OrderHistory.page"
import { Cart } from "../pages/Cart/Cart.page"
import { StartOrder } from "../pages/StartOrder/StartOrder.page"
import { ReviewOrder } from "../pages/ReviewOrder/ReviewOrder.page"
import { InviteUsers } from "../pages/InviteUsers/InviteUsers.page"
import { MyAccount } from "../pages/MyAccount/MyAccount.page"
import { OrderDetails } from "../pages/OrderDetails/OrderDetails.page"
import { RateOrder } from "../pages/RateOrder/RateOrder.page"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../app/hooks"
import { Box } from "@chakra-ui/react"
import { Text } from "../components/Text.component"
import {
  setAuth0,
  setUser,
  setOrg,
  setUserType,
  setToken,
  setFCMToken,
} from "../features/user/userSlice"
import { UserService, CreateUserPayload } from "../services/user.service"
import { ProtectedRoute } from "./ProtectedRoute.component"
import * as Sentry from "@sentry/react"
import React from "react"
import { useLogout } from "../hooks/useLogout"

export const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      {process.env.REACT_APP_ENVIRONMENT === "develop" && (
        <Box backgroundColor={"blue.600"} position={"fixed"} top={"0"} w={"100%"}>
          <Text
            fontSize={"1.2rem"}
            lineHeight={"100%"}
            color={"white"}
            textAlign={"center"}
            fontWeight={"black"}
          >
            DEV
          </Text>
        </Box>
      )}
    </>
  )
}

// Sentry Config
Sentry.init({
  dsn: "https://e77ff081850e63117b6c0482406b4bb4@o4506180892229632.ingest.sentry.io/4506180901339136",
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],

  environment: process.env.REACT_APP_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^\/api.entrega-app.com\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // perform any processing on a Sentry event before it's sent
  beforeSend(event, hint) {
    // prevent errors on localhost from being monitored by Sentry
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      console.warn(
        "Ignoring Sentry event on localhost. On the live sites, this event would be sent: ",
        event,
        hint,
      )
      return null
    }
    return event
  },
})

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const AppRoutes = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0()
  const [auth0Id, setAuth0Id] = useState("")
  const dispatch = useAppDispatch()
  const { logOut } = useLogout()

  // Save Firebase Cloud Messaging token in store
  useEffect(() => {
    if (window.localStorage) {
      dispatch(setFCMToken(window.localStorage.getItem("fcmToken")))
    }
  }, [window.localStorage])

  /**
   * fetch the user record from the DB
   */
  const fetchUser = async (auth0Id: string, token: string) => {
    try {
      const result = await UserService.getUser(auth0Id, token)
      dispatch(setUser(result.data.user))
      dispatch(setAuth0(user))
      dispatch(setOrg(result.data.org))
      dispatch(setUserType(result.data.userType))
      dispatch(setToken(token))
    } catch (err) {
      Sentry.captureException(err)
      logOut()
    }
  }

  /**
   * Create a user record in the DB
   */
  const createUser = async (inviteCode: string, token: string) => {
    if (!user) {
      throw new Error("Auth0 object does not exist.")
    }
    const auth0 = user
    // construct post body
    const body: CreateUserPayload = {
      auth0Id: auth0?.sub ?? "",
      lastName: window.localStorage.getItem("profile-lName") ?? "",
      firstName: window.localStorage.getItem("profile-fName") ?? "",
      phone: window.localStorage.getItem("profile-phone") ?? "",
      email: auth0?.email ?? "",
      inviteCode: inviteCode,
    }
    try {
      const result = await UserService.createUser(body, token)
      window.location.reload()
      dispatch(setUser(result.data))
      dispatch(setAuth0(user))
      dispatch(setOrg(result.data.org))
      dispatch(setUserType(result.data.userType))
      dispatch(setToken(token))
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((accessToken) => {
        if (user && user.sub) {
          setAuth0Id(user.sub)
        }
        /* If there is an invite code in the URL query params, this user has just created
          an account (and will now login for the first time) */
        const inviteCode = window.localStorage.getItem("invite-code")
        // Check if user already exists. If so, log them in.
        if (user?.sub && accessToken) {
          // if they have an invite code, then they don't exist and we need to create their user in the DB
          if (inviteCode) {
            createUser(inviteCode, accessToken)
            window.localStorage.removeItem("invite-code")
          } else {
            fetchUser(user?.sub, accessToken)
          }
        }
      })
    }
  }, [isAuthenticated, getAccessTokenSilently])

  return (
    <SentryRoutes>
      <Route path="/" element={<Splash />}></Route>
      <Route path="/create-user" element={<CreateUser />}></Route>
      <Route path="/create-business" element={<CreateBusiness />}></Route>
      <Route path="/invite-users" element={<ProtectedRoute component={InviteUsers} />}></Route>
      <Route path="/inventory" element={<ProtectedRoute component={Inventory} />}></Route>
      <Route path="/history" element={<ProtectedRoute component={OrderHistory} />}></Route>
      <Route path="/cart" element={<ProtectedRoute component={Cart} />}></Route>
      <Route path="/startOrder" element={<ProtectedRoute component={StartOrder} />}></Route>
      <Route path="/reviewOrder" element={<ProtectedRoute component={ReviewOrder} />}></Route>
      <Route path="/my-account" element={<ProtectedRoute component={MyAccount} />}></Route>
      <Route
        path="/order-details/:orderID"
        element={<ProtectedRoute component={OrderDetails} />}
      ></Route>
      <Route path="/rate-order/:orderID" element={<ProtectedRoute component={RateOrder} />}></Route>
    </SentryRoutes>
  )
}
