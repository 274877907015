import axios from "axios"

const apiUrl = process.env.REACT_APP_API_HOST

const createInvite = async (email: string, isAdmin: boolean, auth0Id: string, token: string) => {
  return await axios.post(
    apiUrl + "/invite",
    {
      email: email,
      isAdmin: isAdmin,
      auth0Id: auth0Id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

const getInvites = async (userId: string, token: string) => {
  return await axios.get(apiUrl + "/invites/" + userId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const getInvite = async (inviteId: string) => {
  return await axios.get(apiUrl + "/invite/" + inviteId)
}

export const InviteService = { createInvite, getInvites, getInvite }
