import { AggregateOrderInfo } from "../../../services/order.service"
import { Card } from "../../../components/Card.component"
import { Box, Flex } from "@chakra-ui/react"
import { Text } from "../../../components/Text.component"
import { OrderStatusIndicator } from "../../../components/OrderStatusIndicator.component"
import { Heading } from "../../../components/Heading.component"
import moment from "moment"
import { PackageIcon } from "../../../components/icons/Package.icon"
import { useNavigate } from "react-router-dom"
import { InventoryItem } from "../Inventory.types"

type OrderInfoCardProps = {
  aggregateOrderInfo: AggregateOrderInfo
  onClickUriTrunk: string
  inventory?: InventoryItem[]
  approval?: boolean
}

export const OrderInfoCard = ({
  aggregateOrderInfo,
  onClickUriTrunk,
  inventory,
  approval,
}: OrderInfoCardProps) => {
  const navigate = useNavigate()

  /**
   * @returns a formatted string detailing the estimated delivery time
   */
  function getDeliveryTimeString(): string {
    const earliestTime = moment(aggregateOrderInfo.order.requestedDelivery)
      .add(30, "m")
      .format("h:mm A")
    const latestTime = moment(aggregateOrderInfo.order.requestedDelivery)
      .add(45, "m")
      .format("h:mm A")
    return `${earliestTime} - ${latestTime}`
  }

  /**
   * @returns a formatted string detailing street and apartment info
   */
  function getStreetAptString() {
    const loc = JSON.parse(aggregateOrderInfo.order.deliveryLocation)
    const addressTwo = loc.addressTwo !== "" ? `, ${loc.addressTwo}` : ``
    return `${loc.addressOne}${addressTwo}`
  }

  /**
   * @returns a formatted string detailing city, state, and zip
   */
  function getCityStateZip() {
    const loc = JSON.parse(aggregateOrderInfo.order.deliveryLocation)
    return `${loc.city}, ${loc.state !== "" ? loc.state : "Ohio"} ${loc.zip}`
  }

  function handleCardClick() {
    if (aggregateOrderInfo?.order?.orderId) {
      navigate(`${onClickUriTrunk}/${aggregateOrderInfo.order.orderId}`, {
        state: { aggregateOrderInfo: aggregateOrderInfo, inventory: inventory },
      })
    }
  }

  return (
    <>
      <Box onClick={handleCardClick} cursor={"pointer"}>
        <Card>
          <Box width={"100%"}>
            <Flex flexDir={"row"} justifyContent="space-between" alignItems={"center"}>
              <Text type="b3Bold">
                {JSON.parse(aggregateOrderInfo?.order?.deliveryLocation).name ?? ""}
              </Text>
              {approval ? "" : <OrderStatusIndicator status={aggregateOrderInfo?.status} />}
            </Flex>
            <Heading type="h5" pt="0.8rem">
              {getDeliveryTimeString()}
            </Heading>
            <Box>
              <Text type="b3">{getStreetAptString()}</Text>
              <Text type="b3">{getCityStateZip()}</Text>
            </Box>
            <Flex flexDir={"row"} mt="0.8rem">
              <PackageIcon height={"1.6rem"} width={"1.6rem"} color={"gray.400"} mr="0.53rem" />
              <Text type="b3">
                {aggregateOrderInfo?.items?.length} item
                {aggregateOrderInfo?.items?.length !== 1 ? "s" : ""}
              </Text>
            </Flex>
          </Box>
        </Card>
      </Box>
    </>
  )
}
