import { HamburgerIcon } from "@chakra-ui/icons"
import {
  Flex,
  Box,
  Spacer,
  Image,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import EntregaLogo from "../../assets/Style=Horizontal.svg"
import { AddUserIcon } from "../icons/AddUser.icon"
import { UserIcon } from "../icons/User.icon"
import { SignOutIcon } from "../icons/SignOut.icon"
import { Text } from "../Text.component"
import { useLogout } from "../../hooks/useLogout"

export const Header = () => {
  const navigate = useNavigate()
  const user = useSelector((state: any) => state.user)
  const orgName = user.org?.name || "Feazel Roofing"
  const { logOut } = useLogout()

  return (
    <>
      <Flex
        direction={"row"}
        padding={"1rem"}
        borderBottom={"1px"}
        borderColor={"gray.300"}
        alignItems={"center"}
      >
        <Image src={EntregaLogo} height={"1.6rem"}></Image>
        <Spacer />
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Text type={"b3"}>{orgName}</Text>
        </Flex>
        <Box ml={"0.625rem"}>
          <Menu>
            <MenuButton variant={"ghost"} as={IconButton} icon={<HamburgerIcon />} />
            <MenuList>
              <MenuItem icon={<UserIcon />} onClick={() => navigate("/my-account")}>
                My Account
              </MenuItem>
              <MenuItem icon={<AddUserIcon />} onClick={() => navigate("/invite-users")}>
                Invite Team Members
              </MenuItem>

              <MenuItem icon={<SignOutIcon />} onClick={logOut}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </>
  )
}
