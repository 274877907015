import axios from "axios"

const apiUrl = process.env.REACT_APP_API_HOST

export type NewAgentFeedbackPayload = {
  orderId: string
  agentId: string
  orderReceived: boolean
  itemsCorrect: boolean
}

/**
 * Create a new agent feedback record
 */
const createAgentFeedback = async (
  feedback: NewAgentFeedbackPayload,
  token: string,
): Promise<any> => {
  return await axios.post(
    `${apiUrl}/create-agent-feedback`,
    { agentFeedback: feedback },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export const AgentFeedbackService = { createAgentFeedback }
