import { useQuery } from "react-query"
import { InventoryService } from "../../../services/inventory.service"

export const useGetInventory = (token: string) => {
  const query = useQuery(["inventory"], () => InventoryService.getInventory(token), {
    retry: false,
    enabled: !!token,
  })

  return { ...query }
}
