import { Search2Icon } from "@chakra-ui/icons"
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup as ChakraInputGroup,
  InputLeftElement,
  InputRightElement,
  Flex,
} from "@chakra-ui/react"
import InputMask from "react-input-mask"
import { Text } from "./Text.component"
import { KnobsIcon } from "./icons/Knobs.icon"

export type InputProps = {
  labelText?: string
  inputIsInvalid?: boolean
  inputPlaceholderText?: string
  helperText?: string
  search?: boolean
  mask?: string | (string | RegExp)[]
} & ChakraInputProps

export const Input = ({ ...props }: InputProps) => {
  return (
    <>
      <Flex direction={"column"}>
        {props.labelText !== undefined && (
          <Text variant={"b3Bold"} mb={"4px"}>
            {props.labelText}
          </Text>
        )}

        <ChakraInputGroup>
          {props.search ? (
            <InputLeftElement
              pointerEvents={"none"}
              children={<Search2Icon color={"gray.400"} />}
            />
          ) : (
            ""
          )}

          {props.mask !== undefined ? (
            <ChakraInput
              as={InputMask}
              isInvalid={props.inputIsInvalid}
              placeholder={props.inputPlaceholderText}
              onChange={props.onChange}
              mb={4}
              mask={props.mask}
              value={props.value}
            />
          ) : (
            <ChakraInput
              isInvalid={props.inputIsInvalid}
              placeholder={props.inputPlaceholderText}
              onChange={props.onChange}
              mb={4}
              type={props.type}
              value={props.value}
            />
          )}
          {/* {props.search ? (
            <InputRightElement pointerEvents={"none"} children={<KnobsIcon color={"gray.400"} />} />
          ) : (
            ""
          )} */}
        </ChakraInputGroup>

        {props.helperText !== undefined && <Text variant={"b3"}>{props.helperText}</Text>}
      </Flex>
    </>
  )
}
