import { Box, Flex, Text } from "@chakra-ui/react"
import { Heading } from "../../../components/Heading.component"
import moment from "moment"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { InviteService } from "../../../services/invite.service"
import { ClockIcon } from "../../../components/icons/Clock.icon"
import { CheckIcon } from "@chakra-ui/icons"

export const SentInvites = (props: any) => {
  const [invites, setInvites] = useState([])

  const user = useSelector((state: any) => state.user)
  const token = useSelector((state: any) => state.user).token

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        const response = await InviteService.getInvites(user.userObj.userId, token)
        setInvites(response.data)
      } catch (err) {
        console.log(err)
      }
    }

    fetchInvites()
  }, [setInvites, props.reRenderList])

  return (
    <>
    {invites.length ?
        <Box border={"solid"} borderColor={"lightgray"} borderRadius={"1rem"} p={"1rem"}>
          <Flex flexDir={"column"}>
              <Heading type={"h2"}>Sent Invites</Heading>
              <Flex flexDir={"row"} justifyContent={"space-between"} p={"1rem"}>
                <Heading type={"h4"}>Email Address</Heading>
                <Heading type={"h4"}>Sent</Heading>
              </Flex>
              <Box maxHeight={"30rem"} overflow={"auto"} p={"1rem"}>
              {invites.map((invite: any) => {
                return(
                  <Flex flexDir={"row"} justifyContent={"space-between"} key={invite.inviteId}>
                    <Flex flexDir={"row"} alignItems={"center"}>
                      <Box pr={"1rem"}>
                        {!invite.useDate ?
                          <ClockIcon />
                          :<CheckIcon />
                        }
                      </Box>
                      <Text>
                        {invite.email}
                      </Text>
                    </Flex>
                    <Text>{moment(new Date(invite.createdAt)).format("M/D/YYYY")}</Text>
                  </Flex>
                )
              })}
              </Box>
          </Flex>
        </Box>
        : ""
    }
    </>
  )
}
