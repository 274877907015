import { Text, Button, Box, Flex } from "@chakra-ui/react"
import { Heading } from "../../components/Heading.component"
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons"
import { CartItem } from "./components/CartItem.component"
import { useEffect } from "react"
import { PageContainer } from "../../components/PageContainer/PageContainer.component"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CartList } from "./Cart.types"

export const Cart = () => {
  const navigate = useNavigate()
  const cartList: CartList = useSelector((state: any) => state.cart.cartItems)
  const currentOrderInfo = useSelector((state: any) => state.order.orderInformation)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PageContainer>
      {cartList && cartList.items.length > 0 ? (
        <Flex flex="1" mt={"0.625rem"} ml={"1rem"} mr={"1rem"} flexDir="column">
          <Box>
            <Button variant={"link"} onClick={() => navigate("/inventory")}>
              <ArrowBackIcon /> Back
            </Button>
          </Box>
          <Box pb={"1rem"}>
            <Heading type="h2" mt={"2rem"}>
              Your Cart
            </Heading>
            {cartList.items.map((item) => {
              return <CartItem key={item.itemId} item={item} inReview={false}></CartItem>
            })}
          </Box>
          <Button mt={"1rem"} onClick={() => navigate("/reviewOrder")}>
            Review Order
          </Button>
        </Flex>
      ) : (
        /* Empty state */
        <Flex
          flex="1"
          backgroundColor={"gray.50"}
          flexDir="column"
          justifyContent={"center"}
          alignItems={"center"}
          height={"100vh"}
        >
          <Box mb="4rem">
            <Text pb="2.4rem" textAlign={"center"}>
              Your cart is empty.
            </Text>
            {currentOrderInfo.jobCode === "" ? (
              <Button onClick={() => navigate("/startOrder")}>
                <AddIcon w={"1.333rem"} h={"1.333rem"} mr="1.133rem" />
                Start New Order
              </Button>
            ) : (
              <Button onClick={() => navigate("/inventory")}>
                <AddIcon w={"1.333rem"} h={"1.333rem"} mr="1.133rem" />
                Add To Cart
              </Button>
            )}
          </Box>
        </Flex>
      )}
    </PageContainer>
  )
}
