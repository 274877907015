import { ArrowBackIcon } from "@chakra-ui/icons"
import { Flex, Box, Button, Spacer, Progress } from "@chakra-ui/react"
import { Text } from "../../../components/Text.component"

type ProgressHeaderProps = {
  progress: number
  steps: number
  backFunction: () => void
}

export const ProgressHeader = ({ progress, steps, backFunction }: ProgressHeaderProps) => {
  return (
    <>
      <Flex>
        <Box>
          <Button variant={"link"} leftIcon={<ArrowBackIcon />} onClick={backFunction}>
            Back
          </Button>
        </Box>
        <Spacer />
        <Box>
          <Text type="b3">{`Step ${progress} of ${steps}`}</Text>
          <Progress value={(progress / steps) * 100}></Progress>
        </Box>
      </Flex>
    </>
  )
}
