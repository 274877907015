import { Button, Modal, ModalOverlay, ModalContent, ModalBody, Flex, Box } from "@chakra-ui/react"
import { Input } from "../../../components/Input.component"
import { Heading } from "../../../components/Heading.component"
import { useAppDispatch } from "../../../app/hooks"
import { updateOrderInformation, OrderMetaData } from "../../../features/order/orderSlice"
import { ChangeEvent, useEffect, useState } from "react"

type EditDeliveryLocationModalProps = {
  isOpen: boolean
  onClose: () => void
  orderInfo: OrderMetaData | null
}

export const EditDeliveryLocationModal = ({
  isOpen,
  onClose,
  orderInfo,
}: EditDeliveryLocationModalProps) => {
  const dispatch = useAppDispatch()
  const [newAddress1, setNewAddress1] = useState("")
  const [newAddress2, setNewAddress2] = useState("")
  const [newCity, setNewCity] = useState("")
  const [newZip, setNewZip] = useState("")
  const [updateDisabled, setUpdateDisabled] = useState(true)

  function updateDeliveryLocation() {
    const orderInformation = {
      jobCode: orderInfo?.jobCode,
      addressOne: newAddress1,
      addressTwo: newAddress2,
      city: newCity,
      zip: newZip,
    }
    dispatch(updateOrderInformation(orderInformation))
    onClose()
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>, setter: (val: string) => void) {
    setter(event.target.value)
  }

  useEffect(() => {
    setUpdateDisabled(!Boolean(newAddress1 && newCity && newZip))
  }, [newAddress1, newCity, newZip])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="95%">
          <ModalBody p={"1.6rem"}>
            <Heading type="h4" pb="1.6rem">
              Change Delivery Location
            </Heading>
            <Input
              value={newAddress1}
              labelText={"Address Line 1"}
              onChange={(e) => onInputChange(e, setNewAddress1)}
            />
            <Input
              value={newAddress2}
              labelText={"Address Line 2 (Optional)"}
              onChange={(e) => onInputChange(e, setNewAddress2)}
            />
            <Flex columnGap={"1.6rem"}>
              <Box flex={"1.5"}>
                <Input
                  value={newCity}
                  labelText={"City"}
                  onChange={(e) => onInputChange(e, setNewCity)}
                />
              </Box>
              <Box flex={"1"} minWidth={"10rem"}>
                <Input
                  value={newZip}
                  labelText={"ZIP / Postal Code"}
                  onChange={(e) => onInputChange(e, setNewZip)}
                  type="number"
                />
              </Box>
            </Flex>
            <Button w="100%" mb="0.8rem" onClick={updateDeliveryLocation} disabled={updateDisabled}>
              Update
            </Button>
            <Button w="100%" onClick={onClose} variant={"secondary"}>
              Go Back
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
