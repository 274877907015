import { useState } from "react"
import { Container, Flex } from "@chakra-ui/react"
import { InviteCode } from "./components/InviteCode"
import { OrganizationVerification } from "./components/OrganizationVerification"
import { TermsOfService } from "./components/TermsOfService"
import { ProgressHeader } from "./components/ProgressHeader"
import { UserTypeSelect } from "./components/UserTypeSelect"
import { useNavigate } from "react-router-dom"
import { ProfileDetails } from "./components/ProfileDetails"

export const CreateUser = () => {
  const [userType, setUserType] = useState<"admin" | "member" | "driver" | undefined>(undefined)
  const [inviteCode, setInviteCode] = useState("")
  const [organization, setOrganization] = useState("")
  const [terms, setTerms] = useState(false)
  const [progress, setProgress] = useState(1)

  const navigate = useNavigate()

  function submitInviteCode() {
    //validate invite code format
    //Obtain Organization using invite code
    setOrganization(inviteCode)
  }

  const incrementProgress = () => {
    setProgress(progress + 1)
  }

  const decrementProgress = () => {
    setProgress(progress - 1)
  }

  function backFunction() {
    if (progress === 1) {
      navigate("/")
    } else {
      decrementProgress()
    }
  }

  return (
    <Container>
      <Flex height={"100vh"} flexDir="column" p="1.6rem">
        <ProgressHeader progress={progress} steps={6} backFunction={backFunction} />
        {progress === 1 && (
          <UserTypeSelect setUserType={setUserType} incrementProgress={incrementProgress} />
          )}
        {progress === 2 && (
          <InviteCode
          setInviteCode={setInviteCode}
          inviteCode={inviteCode}
          submitCode={submitInviteCode}
          incrementProgress={incrementProgress}
          />
          )}

        {progress === 3 && (
          <TermsOfService setTerms={setTerms} incrementProgress={incrementProgress} />
          )}

        {progress === 4 && (
          <ProfileDetails incrementProgress={incrementProgress}/>
        )}
        {progress === 5 && (
          <OrganizationVerification
          organizationID={organization}
          inviteCode={inviteCode}
          incrementProgress={incrementProgress}
          />
          )}
      </Flex>
    </Container>
  )
}
