import { useAuth0 } from "@auth0/auth0-react"
import { useDispatch } from "react-redux"
import { clearCart } from "../features/cart/cartSlice"
import { clearOrderInfo } from "../features/order/orderSlice"
import { clearUser } from "../features/user/userSlice"

export const useLogout = () => {
  const { logout } = useAuth0()
  const dispatch = useDispatch()

  function logOut() {
    // clear Redux store
    dispatch(clearCart)
    dispatch(clearOrderInfo)
    dispatch(clearUser)
    // wipe Redux Persist state in local storage
    localStorage.removeItem("persist:root")
    // logout Auth0 user
    logout({ returnTo: `${window.location.origin}/` })
  }

  return { logOut }
}
