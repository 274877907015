import axios from "axios"

const apiUrl = process.env.REACT_APP_API_HOST

const subscribeToTopic = async (auth0Roles: string[], fcmToken: string, accessToken: string) => {
  const payload = {
    auth0Roles: auth0Roles,
    fcmToken: fcmToken,
  }
  return await axios.post(`${apiUrl}/subscribe-to-topic`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const PushNotificationService = { subscribeToTopic }
