import { Box, Button } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { AggregateOrderInfo, OrderRecord, OrderService } from "../../services/order.service"
import { PageContainer } from "../../components/PageContainer/PageContainer.component"
import { useSelector } from "react-redux"
import { Text } from "../../components/Text.component"
import { Heading } from "../../components/Heading.component"
import {
  NewAgentFeedbackPayload,
  AgentFeedbackService,
} from "../../services/agent-feedback.service"

export const RateOrder = () => {
  const { orderID } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [order, setOrder] = useState<OrderRecord | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [contentIndex, setContentIndex] = useState(0)
  const [gotOrder, setGotOrder] = useState<boolean>(true)
  const token = useSelector((state: any) => state.user).token

  // fetch the order via order ID
  useEffect(() => {
    if (!token) return
    else if (!orderID) {
      navigate("/inventory")
    } else {
      if (location.state?.order) {
        setOrder(location.state.order)
        setIsLoading(false)
      } else {
        OrderService.getOrder(orderID, token).then((agg: AggregateOrderInfo) => {
          setOrder(agg.order)
          setIsLoading(false)
        })
      }
    }
  }, [orderID])

  /**
   * Click handler for the "did you get your order" screen
   * @param gotOrder whether or not the user received their order
   */
  function answerGotOrder(gotOrder: boolean) {
    setGotOrder(gotOrder)
    setContentIndex(contentIndex + 1)
  }

  /**
   * Click handler for the "are all items correct" screen
   * @param itemsCorrect whether or not the items were correct
   */
  function answerItemsCorrect(itemsCorrect: boolean) {
    submitRating(itemsCorrect)
  }

  function submitRating(itemsCorrect: boolean) {
    if (!order) {
      throw new Error("No order is loaded.")
    }
    const feedback_obj: NewAgentFeedbackPayload = {
      orderId: order.orderId,
      agentId: order.orderedBy,
      orderReceived: gotOrder,
      itemsCorrect: itemsCorrect,
    }
    setIsLoading(true)
    AgentFeedbackService.createAgentFeedback(feedback_obj, token).then(() => {
      setContentIndex(contentIndex + 1)
      setIsLoading(false)
    })
  }

  /**
   * Click handler for the "back to orders" button
   */
  function backToOrders() {
    navigate("/inventory")
  }

  return (
    <PageContainer isLoading={isLoading}>
      {order && (
        <Box mx={"1.6rem"} mt={"2.4rem"}>
          <Text type={"b1"}>{JSON.parse(order.deliveryLocation).name}</Text>
          {contentIndex === 0 && (
            <Box>
              <Heading type={"h2"} py={"1.6rem"}>
                Did you get your order?
              </Heading>
              <Button w={"100%"} mb={"0.8rem"} onClick={() => answerGotOrder(true)}>
                Yes
              </Button>
              <Button variant={"secondary"} w={"100%"} onClick={() => answerGotOrder(false)}>
                No
              </Button>
            </Box>
          )}
          {contentIndex === 1 && (
            <Box>
              <Heading type={"h2"} py={"1.6rem"}>
                Are all items correct?
              </Heading>
              <Button w={"100%"} mb={"0.8rem"} onClick={() => answerItemsCorrect(true)}>
                Yes
              </Button>
              <Button variant={"secondary"} w={"100%"} onClick={() => answerItemsCorrect(false)}>
                No
              </Button>
            </Box>
          )}
          {contentIndex === 2 && (
            <Box>
              <Heading type={"h2"} py={"1.6rem"}>
                Your feedback was successfully submitted
              </Heading>
              <Button w={"100%"} mb={"0.8rem"} onClick={backToOrders}>
                Back to Orders
              </Button>
            </Box>
          )}
        </Box>
      )}
    </PageContainer>
  )
}
