import { useCallback } from "react"
import {
  BottomNavigation,
  BottomNavigationIcon,
  BottomNavigationItem,
  BottomNavigationLabel,
} from "chakra-ui-bottom-navigation"
import { HomeIcon } from "../icons/Home.icon"
import { TruckIcon } from "../icons/Truck.icon"
import { ShoppingCartIcon } from "../icons/ShoppingCart.icon"
import { useNavigate, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { CartList } from "../../pages/Cart/Cart.types"

export const BottomNav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const itemCount: number = useSelector((state: any) => state.cart.cartItems.items.length)

  const handleChange = useCallback(
    (path: string) => {
      navigate(path)
    },
    [navigate],
  )

  return (
    <BottomNavigation value={location.pathname} onChange={(value: string) => handleChange(value)}>
      <BottomNavigationItem value="/inventory">
        <BottomNavigationIcon as={HomeIcon} />
        <BottomNavigationLabel>Home</BottomNavigationLabel>
      </BottomNavigationItem>
      <BottomNavigationItem value="/history">
        <BottomNavigationIcon as={TruckIcon} />
        <BottomNavigationLabel>History</BottomNavigationLabel>
      </BottomNavigationItem>
      <BottomNavigationItem value="/cart">
        <BottomNavigationIcon as={ShoppingCartIcon} />
        <BottomNavigationLabel>Cart {itemCount ? `(${itemCount})`: ""}</BottomNavigationLabel>
      </BottomNavigationItem>
    </BottomNavigation>
  )
}
