import axios from "axios"

const apiUrl = process.env.REACT_APP_API_HOST

const getAgentsByOrg = async (orgId: string, token: string) => {
  return await axios.get(`${apiUrl}/agents/${orgId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const AgentsService = { getAgentsByOrg }
