import { Flex, Box, useToast } from "@chakra-ui/react"
import { BottomNav } from "./BottomNav.component"
import { Header } from "./Header.component"
import { useAuth0 } from "@auth0/auth0-react"
import { LoadingScreen } from "../LoadingScreen.component"
import { useEffect } from "react"

type PageContainerProps = {
  success?: ToastMsg
  error?: ToastMsg
  isLoading?: boolean
  children?: any
}

export type ToastMsg = {
  title?: string
  description?: string
}

export const PageContainer = (props: PageContainerProps) => {
  const toast = useToast()

  useEffect(() => {
    if (props.success && props.success.title) {
      toast({
        title: `${props.success?.title || ""}`,
        description: `${props.success?.description || ""}`,
        position: "top",
        status: "success",
        isClosable: true,
      })
    }
  }, [toast, props.success])

  useEffect(() => {
    if (props.error && (props.error.title || props.error.description)) {
      toast({
        title: `${props.error?.title || "Error"}`,
        description: `${props.error?.description || ""}`,
        position: "top",
        status: "error",
        isClosable: true,
        duration: null,
      })
    }
  }, [toast, props.error])

  return (
    <>
      <Flex height={"100vh"} flexDir="column">
        <Header />
        <Box pb={"4.5rem"}>{props.children}</Box>
        <BottomNav />
      </Flex>
      {props.isLoading ? <LoadingScreen /> : ""}
    </>
  )
}
