import { ArrowBackIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { Heading } from "../../components/Heading.component"
import { PageContainer } from "../../components/PageContainer/PageContainer.component"

export const MyAccount = () => {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user).userObj
  const [name, setName] = useState("")

  useEffect(() => {
    const fullName: string = `${user?.firstName} ${user?.lastName}`
    setName(fullName)
  }, [user])

  return (
    <PageContainer>
      <Flex flex="1" mt={"0.625rem"} ml={"1rem"} mr={"1rem"} flexDir="column">
        <Box>
          <Button variant={"link"} onClick={() => navigate("/inventory")}>
            <ArrowBackIcon /> Back
          </Button>
        </Box>
        <Box pb={"2rem"}>
          <Heading type="h2" mt={"2rem"}>
            My Account
          </Heading>
        </Box>
        <Box pb={"1rem"} minHeight="108">
          <Flex flex="1" flexDir="row" justifyContent="space-between" alignItems="center">
            <Heading type="h4">Name</Heading>
          </Flex>
          <Text pb="2.4rem" textAlign="left">
            {name}
          </Text>
        </Box>
        <Box pb={"1rem"} minHeight="108">
          <Flex flex="1" flexDir="row" justifyContent="space-between" alignItems="center">
            <Heading type="h4">Email</Heading>
          </Flex>
          <Text pb="2.4rem" textAlign="left">
            {user?.email}
          </Text>
        </Box>
      </Flex>
    </PageContainer>
  )
}
