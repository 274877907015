import { Flex, Button, Box } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { AddIcon } from "@chakra-ui/icons"
import { Heading } from "../../../components/Heading.component"
import { AggregateOrderInfo, OrderStatusEnum } from "../../../services/order.service"
import { OrderInfoCard } from "./OrderInfoCard.component"
import { InventoryItem } from "../Inventory.types"

type OrdersInProgressProps = {
  aggregateOrderInfoList: Array<AggregateOrderInfo>
  inventory?: InventoryItem[]
}

export const OrdersInProgress = ({ aggregateOrderInfoList, inventory }: OrdersInProgressProps) => {
  const navigate = useNavigate()

  const ordersInProgressCards = () => {
    return (
      <>
        {aggregateOrderInfoList.map((aggregateOrderInfo: AggregateOrderInfo, index: number) => {
          if (aggregateOrderInfo.status.status !== OrderStatusEnum.NeedsRating) {
            return (
              <Box mt="1.6rem" key={index}>
                <OrderInfoCard
                  aggregateOrderInfo={aggregateOrderInfo}
                  inventory={inventory}
                  onClickUriTrunk={"/order-details"}
                />
              </Box>
            )
          } else return ""
        })}
      </>
    )
  }

  const hasOrdersNeedingReview = () => {
    return aggregateOrderInfoList.some(
      (agg: AggregateOrderInfo) => agg.status.status === OrderStatusEnum.NeedsRating,
    )
  }

  /** Build the card list for all orders that need a rating */
  const ordersNeedRatingCards = () => {
    return (
      <>
        {aggregateOrderInfoList.map((aggregateOrderInfo: AggregateOrderInfo, index: number) => {
          if (aggregateOrderInfo.status.status === OrderStatusEnum.NeedsRating) {
            return (
              <Box mt="1.6rem" key={index}>
                <OrderInfoCard
                  aggregateOrderInfo={aggregateOrderInfo}
                  inventory={inventory}
                  onClickUriTrunk={"/order-details"}
                />
              </Box>
            )
          } else return ""
        })}
      </>
    )
  }

  return (
    <Flex
      flex="1"
      flexDir="column"
      backgroundColor={"gray.50"}
      height={"100vh"}
      px="1.6rem"
      pt="2.4rem"
    >
      <Flex flexDir={"row"} justifyContent="space-between">
        <Heading type="h2">Order List</Heading>
        <Button onClick={() => navigate("/startOrder")}>
          <AddIcon w={"1.333rem"} h={"1.333rem"} mr="1.133rem" />
          Start New Order
        </Button>
      </Flex>
      {hasOrdersNeedingReview() && (
        <>
          <Heading type="h4" pt="2.4rem" pb="0rem">
            Provide a Rating
          </Heading>
          <Box>{ordersNeedRatingCards()}</Box>
        </>
      )}
      <Heading type="h4" pt="2.4rem" pb="0rem">
        In Progress
      </Heading>
      <Box pb={"6rem"}>{ordersInProgressCards()}</Box>
    </Flex>
  )
}
