import { PageContainer, ToastMsg } from "../../components/PageContainer/PageContainer.component"
import { Flex, Box, Button, Spacer, Textarea } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom"
import { Heading } from "../../components/Heading.component"
import { useSelector } from "react-redux"
import { CartList } from "../Cart/Cart.types"
import { DeliveryLocation } from "./components/DeliveryLocation.component"
import { CartItem } from "../Cart/components/CartItem.component"
import { EstimatedDeliveryTime } from "../../components/EstimatedDeliveryTime/EstimatedDeliveryTime.component"
import { OrderService, OrderStatusEnum } from "../../services/order.service"
import { useState } from "react"
import { SimpleModal } from "../../components/SimpleModal.component"
import { useAppDispatch } from "../../app/hooks"
import { clearCart } from "../../features/cart/cartSlice"
import { OrderMetaData, clearOrderInfo } from "../../features/order/orderSlice"

export const ReviewOrder = (props: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ToastMsg>({})
  const [comments, setComments] = useState("")
  const navigate = useNavigate()
  const cartList: CartList = useSelector((state: any) => state.cart.cartItems)
  const orderInformation = useSelector(
    (state: any) => state.order.orderInformation,
  ) as OrderMetaData
  const userId: string = useSelector((state: any) => state.user.userObj.userId)
  const orgId: string = useSelector((state: any) => state.user.org.orgId)
  const token = useSelector((state: any) => state.user).token
  const dispatch = useAppDispatch()
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] = useState(false)

  const submitOrder = async () => {
    try {
      setIsLoading(true)
      const orderRes = await OrderService.placeOrder(
        {
          orderInformation: orderInformation,
          orgId: orgId,
          orderedById: userId,
          requestedDelivery: new Date(),
          comments: comments,
          orderedItems: cartList.items,
        },
        token,
      )
      await OrderService.updateOrderStatus(
        orderRes.data.orderInfoResponse.orderId,
        OrderStatusEnum.Awaiting,
        props.userId,
        token,
      )
      setIsApproveConfirmationOpen(true)
    } catch (err: any) {
      setError({ description: "Could not place order. There was an error." })
      throw err
    }
  }

  function approveConfirmation() {
    setIsApproveConfirmationOpen(false)
    setIsLoading(true)
    dispatch(clearCart())
    dispatch(clearOrderInfo())
    navigate("/inventory")
  }

  return (
    <PageContainer isLoading={isLoading} error={error}>
      <SimpleModal
        isOpen={isApproveConfirmationOpen}
        onClose={() => approveConfirmation()}
        bodyContent={`Your order ${orderInformation.jobCode} has been saved and is awaiting approval from your admin.`}
      />
      <Flex mt={"0.625rem"} ml={"1rem"} mr={"1rem"} direction={"column"}>
        <Box>
          <Button variant={"link"} onClick={() => navigate("/cart")}>
            <ArrowBackIcon /> Back
          </Button>
        </Box>
        <Heading type="h2" mt={"16px"}>
          Order Review
        </Heading>
        <Flex direction={"row"} mt={"16px"}>
          <Heading type={"h4"}>Your Cart</Heading>
          <Spacer />
          <Button variant={"secondary"} size={"sm"} onClick={() => navigate("/cart")}>
            Edit Cart
          </Button>
        </Flex>
        <Box pb={"1rem"} borderBottom={"1px"} borderColor={"gray.300"}>
          {cartList.items.map((item) => {
            return <CartItem key={item.itemId} item={item} inReview={true}></CartItem>
          })}
        </Box>
        <Box pb={"1rem"} borderBottom={"1px"} borderColor={"gray.300"}>
          <DeliveryLocation orderInformation={orderInformation} />
        </Box>
        <Box pb={"1rem"} borderBottom={"1px"} borderColor={"gray.300"}>
          <EstimatedDeliveryTime />
        </Box>
        <Box pb={"1rem"} borderBottom={"1px"} borderColor={"gray.300"}>
          <Heading mt={"16px"} type={"h6"}>
            Comments (Optional)
          </Heading>
          <Textarea value={comments} onChange={(e) => setComments(e.target.value)}></Textarea>
        </Box>
        <Button variant={"primary"} width={"100%"} onClick={submitOrder} mt={"16px"}>
          Submit Order
        </Button>
      </Flex>
    </PageContainer>
  )
}
