import { Box } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Text } from "../components/Text.component"
import { OrderStatusEnum, OrderStatusType } from "../services/order.service"

type OrderStatusIndicatorProps = {
  status: OrderStatusType | null
}

/** Defines the shape of each of the status values in the Status const below */
class OrderStatus {
  label: string
  bgColor: string
  fontColor: string

  constructor(params: { label: string; bgColor: string; fontColor: string }) {
    this.label = params.label
    this.bgColor = params.bgColor
    this.fontColor = params.fontColor
  }
}

export const OrderStatusIndicator = ({ status }: OrderStatusIndicatorProps) => {
  // Define labels and colors for the indicator based on the status of the order
  const Status = {
    Unclaimed: new OrderStatus({
      label: "Unclaimed",
      bgColor: "var(--chakra-colors-gray-300)",
      fontColor: "var(--chakra-colors-chakra-body-text)",
    }),
    Claimed: new OrderStatus({
      label: "Claimed by Driver",
      bgColor: "var(--chakra-colors-gray-600)",
      fontColor: "white",
    }),
    Loading: new OrderStatus({
      label: "Loading Shipment",
      bgColor: "var(--chakra-colors-blue-400)",
      fontColor: "white",
    }),
    OutForDelivery: new OrderStatus({
      label: "Out for Delivery",
      bgColor: "var(--chakra-colors-green-400)",
      fontColor: "white",
    }),
    Completed: new OrderStatus({
      label: "Completed",
      bgColor: "rgba(0,0,0,0)",
      fontColor: "var(--chakra-colors-gray-600)",
    }),
    Cancelled: new OrderStatus({
      label: "Cancelled",
      bgColor: "rgba(0,0,0,0)",
      fontColor: "var(--chakra-colors-red-400)",
    }),
    Awaiting: new OrderStatus({
      label: "Awaiting Approval",
      bgColor: "rgba(0,0,0,0)",
      fontColor: "var(--chakra-colors-gray-600)",
    }),
    NeedsRating: new OrderStatus({
      label: "Needs Rating",
      bgColor: "rgba(0,0,0,0)",
      fontColor: "var(--chakra-colors-green-400)",
    }),
    Rejected: new OrderStatus({
      label: "Rejected",
      bgColor: "rgba(0,0,0,0)",
      fontColor: "var(--chakra-colors-red-400)",
    }),
  }

  const [orderStatus, setStatus] = useState(Status.Unclaimed)

  useEffect(() => {
    if (status) {
      switch (status.status) {
        case OrderStatusEnum.Claimed:
          setStatus(Status.Claimed)
          break
        case OrderStatusEnum.Loading:
          setStatus(Status.Loading)
          break
        case OrderStatusEnum.Out:
          setStatus(Status.OutForDelivery)
          break
        case OrderStatusEnum.Delivered:
          setStatus(Status.Completed)
          break
        case OrderStatusEnum.Cancelled:
          setStatus(Status.Cancelled)
          break
        case OrderStatusEnum.NeedsRating:
          setStatus(Status.NeedsRating)
          break
        case OrderStatusEnum.Rejected:
          setStatus(Status.Rejected)
          break
        case OrderStatusEnum.Awaiting:
          setStatus(Status.Awaiting)
          break
        case OrderStatusEnum.Approved:
        default:
          setStatus(Status.Unclaimed)
      }
    } else {
      setStatus(Status.Unclaimed)
    }
  }, [status])

  return (
    <>
      <Box
        bgColor={orderStatus.bgColor}
        px={
          status?.status === OrderStatusEnum.Cancelled ||
          status?.status === OrderStatusEnum.Delivered ||
          status?.status === OrderStatusEnum.NeedsRating ||
          status?.status === OrderStatusEnum.Awaiting
            ? "0rem"
            : "0.8rem"
        }
        py={"0.4rem"}
        borderRadius={"10rem"}
        width={"fit-content"}
      >
        <Text type="b3" color={orderStatus.fontColor}>
          {orderStatus.label}
        </Text>
      </Box>
    </>
  )
}
