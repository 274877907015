import { AddIcon } from "@chakra-ui/icons"
import { Box, Flex, Text, Button, Center } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { Heading } from "../../../components/Heading.component"


export const OrderHistoryEmptyState = () => {
  const navigate = useNavigate()

  return (
    <Flex
      flex="1"
      flexDir="column"
      backgroundColor={"gray.50"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      >
        <Box mb="2rem">
          <Heading type="h3" pb="1rem" textAlign={"center"}>
            No Order History
          </Heading>
          <Center>
            <Text width="55%" textAlign={"center"}>
              After your first order has been completed, you'll be able to view it here
            </Text>
          </Center>
        </Box>
        <Button onClick={() => navigate("/startOrder")}>
            <AddIcon w={"1.333rem"} h={"1.333rem"} mr="1.33rem"/>
            Start New Order
          </Button>
      </Flex>
  )
}