import { Flex, Box } from "@chakra-ui/react"
import { Heading } from "../../../components/Heading.component"
import { AggregateOrderInfo } from "../../../services/order.service"
import { OrderInfoCard } from "../../Inventory/components/OrderInfoCard.component"

type OrderHistoryProps = {
  aggregateOrderInfo: Array<AggregateOrderInfo>
}

export const OrderHistory = ({ aggregateOrderInfo }: OrderHistoryProps) => {
  const orderCards = () => {
    return (
      <>
        {aggregateOrderInfo.map((aggregateOrderInfo: AggregateOrderInfo, index: number) => {
          return (
            <Box mt="1.6rem" key={index}>
              <OrderInfoCard
                aggregateOrderInfo={aggregateOrderInfo}
                onClickUriTrunk={"/order-details"}
              />
            </Box>
          )
        })}
      </>
    )
  }

  return (
    <Flex
      flex="1"
      flexDir="column"
      backgroundColor="gray.50"
      height={"100vh"}
      px="1.6rem"
      pt="2.4rem"
    >
      <Flex flexDir="row" justifyContent="space-between">
        <Heading type="h2">Order History</Heading>
      </Flex>
      {orderCards()}
    </Flex>
  )
}
