export const textStyles = {
  display: {
    fontSize: { base: "6.4rem", lg: "7.2rem" },
    fontStyle: "normal",
    lineHeight: { base: "6.8rem", lg: "7.6rem" },
    fontWeight: 700,
  },
  h1: {
    fontSize: { base: "3.4rem", lg: "4.4rem" },
    fontStyle: "normal",
    lineHeight: { base: "3.8rem", lg: "4.8rem" },
    fontWeight: 700,
  },
  h2: {
    fontSize: { base: "2.6rem", lg: "3.4rem" },
    fontStyle: "normal",
    lineHeight: { base: "3.0rem", lg: "3.8rem" },
    fontWeight: 700,
  },
  h3: {
    fontSize: { base: "2.2rem", lg: "2.6rem" },
    fontStyle: "normal",
    lineHeight: { base: "2.6rem", lg: "3.0rem" },
    fontWeight: 700,
  },
  h4: {
    fontSize: { base: "1.8rem", lg: "2.2rem" },
    fontStyle: "normal",
    lineHeight: { base: "2.2rem", lg: "2.6rem" },
    fontWeight: 700,
  },
  h5: {
    fontSize: { base: "1.5rem", lg: "1.8rem" },
    fontStyle: "normal",
    lineHeight: { base: "1.8rem", lg: "2.2rem" },
    fontWeight: 700,
  },
  h6: {
    fontSize: { base: "1.2rem", lg: "1.5rem" },
    fontStyle: "normal",
    lineHeight: { base: "1.5rem", lg: "1.8rem" },
    fontWeight: 700,
  },
  b1Regular: {
    fontSize: "1.8rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "2.2rem",
  },
  b1Bold: {
    fontSize: "1.8rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "2.2rem",
  },
  b2Regular: {
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.8rem",
  },
  b2Bold: {
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.8rem",
  },
  b3Regular: {
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.5rem",
  },
  b3Bold: {
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.5rem",
  },
  overline: {
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.5rem",
    letterSpacing: "0.32rem",
    fontVariant: "small-caps",
  },
}
