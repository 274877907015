import { ComponentStyleConfig } from "@chakra-ui/react"

export const HeadingStyles: ComponentStyleConfig = {
  baseStyle: {
    paddingBottom: 2,
  },
  variants: {
    display: {
      fontSize: ["6.4rem", null, "7.2rem", null, null, null],
      fontStyle: "normal",
      lineHeight: ["6.8rem", null, "7.6rem", null, null, null],
      fontWeight: 700,
    },
    h1: {
      fontSize: ["3.4rem", null, "4.4rem", null, null, null],
      fontStyle: "normal",
      lineHeight: ["3.8rem", null, "4.8rem", null, null, null],
      fontWeight: 700,
    },
    h2: {
      fontSize: ["2.6rem", null, "3.4rem", null, null, null],
      fontStyle: "normal",
      lineHeight: ["3.0rem", null, "3.8rem", null, null, null],
      fontWeight: 700,
    },
    h3: {
      fontSize: ["2.2rem", null, "2.6rem", null, null, null],
      fontStyle: "normal",
      lineHeight: ["2.6rem", null, "3.0rem", null, null, null],
      fontWeight: 700,
    },
    h4: {
      fontSize: ["1.8rem", null, "2.2rem", null, null, null],
      fontStyle: "normal",
      lineHeight: ["2.2rem", null, "2.6rem", null, null, null],
      fontWeight: 700,
    },
    h5: {
      fontSize: ["1.5rem", null, "1.8rem", null, null, null],
      fontStyle: "normal",
      lineHeight: ["1.8rem", null, "2.2rem", null, null, null],
      fontWeight: 700,
    },
    h6: {
      fontSize: ["1.2rem", null, "1.5rem", null, null, null],
      fontStyle: "normal",
      lineHeight: ["1.5rem", null, "1.8rem", null, null, null],
      fontWeight: 700,
    },
  },
}
