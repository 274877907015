import { createSlice } from "@reduxjs/toolkit"
import { CartList } from "../../pages/Cart/Cart.types"

interface CartState {
  cartItems: CartList | null
  subTotal: number
}

const initialState: CartState = {
  cartItems: { items: [] },
  subTotal: 0,
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.cartItems?.items.push(action.payload)
      state.subTotal += action.payload.cost * action.payload.count
    },
    removeFromCart: (state, action) => {
      if (state.cartItems !== null) {
        const tempCart = state.cartItems.items.filter(
          (item) => item.itemId !== action.payload.itemId,
        )
        state.cartItems.items = tempCart
        state.subTotal = state.cartItems.items.reduce((accumulator, item) => {
          return accumulator + item.price * item.count
        }, 0)
      }
    },
    updateCart: (state, action) => {
      if (state.cartItems !== null) {
        const tempCart = state.cartItems.items.map((item) =>
          item.itemId === action.payload.itemId && item.variation === action.payload.variation
            ? (item = action.payload)
            : item,
        )
        state.cartItems.items = tempCart
        state.subTotal = state.cartItems.items.reduce((accumulator, item) => {
          return accumulator + item.price * item.count
        }, 0)
      }
    },
    decrementQuantity: (state, action) => {
      if (state.cartItems !== null) {
        const tempCart = state.cartItems.items.map((item) => {
          if (
            item.itemId === action.payload.itemId &&
            item.variation === action.payload.variation &&
            item.count > 0
          ) {
            item.count -= 1
          }
          return item
        })
        state.cartItems.items = tempCart
        state.subTotal = state.cartItems.items.reduce((accumulator, item) => {
          return accumulator + item.price * item.count
        }, 0)
      }
    },
    incrementQuantity: (state, action) => {
      if (state.cartItems !== null) {
        const tempCart = state.cartItems.items.map((item) => {
          if (
            item.itemId === action.payload.itemId &&
            item.variation === action.payload.variation
          ) {
            item.count += 1
          }
          return item
        })
        state.cartItems.items = tempCart
        state.subTotal = state.cartItems.items.reduce((accumulator, item) => {
          return accumulator + item.price * item.count
        }, 0)
      }
    },
    clearCart: (state) => {
      state.cartItems = { items: [] }
      state.subTotal = 0
    },
  },
})

export const {
  addToCart,
  removeFromCart,
  updateCart,
  decrementQuantity,
  incrementQuantity,
  clearCart,
} = cartSlice.actions

export default cartSlice.reducer
