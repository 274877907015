import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Flex,
  chakra,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react"

export type SimpleDialogProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  text: string
} & ChakraInputProps

export const SimpleDialog = chakra(({ isOpen, onClose, onConfirm, text }: SimpleDialogProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="95%">
          <ModalBody mt={"0.8rem"}>{text}</ModalBody>
          <ModalFooter>
            <Flex flexDir={"row"} columnGap={"1rem"} w={"100%"}>
              <Button variant={"secondary"} onClick={onConfirm} flexGrow={"1"}>
                Yes
              </Button>
              <Button variant={"primary"} onClick={onClose} flexGrow={"1"}>
                No
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
})
