import { ComponentStyleConfig } from "@chakra-ui/react"
import { textStyles } from "../foundations/textStyles"

export const BadgeStyles: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "10rem",
    color: "black-and-white.white",
    ...textStyles.b2Regular,
    px: "0.8rem",
    py: "1.6rem",
  },
  variants: {
    light: {
      bg: "gray.300",
      color: "gray.900",
    },
    dark: {
      bg: "gray.600",
    },
    blue: {
      bg: "blue.400",
    },
    green: {
      bg: "green.400",
    },
    red: {
      bg: "red.400",
    },
  },
}
