import { Flex } from "@chakra-ui/react"
import { Heading } from "../Heading.component"
import { Text } from "../Text.component"
import moment from "moment"

type EstimatedDeliveryTimeProps = {
  time?: Date
}

export const EstimatedDeliveryTime = ({ time }: EstimatedDeliveryTimeProps) => {
  const deliveryTime = () => {
    return time ?? new Date()
  }

  return (
    <Flex direction={"column"} mt={"1.6rem"}>
      <Heading type={"h4"}>Estimated Delivery Time</Heading>
      <Text type={"b1"} mt={"1.6rem"}>
        {moment(deliveryTime()).add(30, "m").format("h:mm a").toString().toUpperCase()} -{" "}
        {moment(deliveryTime()).add(45, "m").format("h:mm a").toString().toUpperCase()}
      </Text>
      <Text type={"b3"} mt={"0.8rem"}>
        Orders usually take 30-45 minutes to be delivered.
      </Text>
    </Flex>
  )
}
