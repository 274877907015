import { Flex, Box, Spinner } from "@chakra-ui/react"

/**
 * A loading animation that takes up the whole screen.
 */
export const LoadingScreen = () => {
  return (
    <Box
      top={"0px"}
      left={"0px"}
      zIndex={"1000"}
      width={"100%"}
      height={"100vh"}
      background={"gray.500"}
      opacity={"0.5"}
      position={"fixed"}
    >
      <Flex flex="1" flexDir="column" justifyContent={"center"} alignItems={"center"} h={"100vh"}>
        <Spinner />
      </Flex>
    </Box>
  )
}
