import { Flex, Box, Button, Center } from "@chakra-ui/react"
import { SetStateAction } from "react"
import { Heading } from "../../../components/Heading.component"
import { useAuth0 } from "@auth0/auth0-react"

type TypeSelectProps = {
  setUserType: (type: SetStateAction<"admin" | "member" | "driver" | undefined>) => void
  incrementProgress: () => void
}

export const UserTypeSelect = ({ setUserType, incrementProgress }: TypeSelectProps) => {
  const { loginWithPopup } = useAuth0()

  const updateUserType = (type: SetStateAction<"admin" | "member" | "driver" | undefined>) => {
    if (type === "admin") {
      loginWithPopup({ screen_hint: "signup" })
    } else {
      setUserType(type)
      incrementProgress()
    }
  }

  return (
    <>
      <Flex direction={"column"} w="100%">
        <Heading type={"h2"} pt="3.2rem">
          I want to...
        </Heading>
        <Box padding="5px">
          <Button variant={"primary"} w="100%" onClick={() => updateUserType("member")}>
            Join an organization
          </Button>
        </Box>
      </Flex>
    </>
  )
}
