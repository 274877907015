import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  chakra,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react"

export type SimpleModalProps = {
  isOpen: boolean
  onClose: () => void
  bodyContent: string
} & ChakraInputProps

export const SimpleModal = chakra(({ isOpen, onClose, bodyContent }: SimpleModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="95%">
          <ModalBody mt={"0.8rem"}>{bodyContent}</ModalBody>
          <ModalFooter>
            <Button w="100%" onClick={onClose}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
})
