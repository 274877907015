import { useEffect, useState } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import {
  OrderService,
  AggregateOrderInfo,
  OrderRecord,
  OrderItemRecord,
  OrderStatusType,
  OrderStatusEnum,
} from "../../services/order.service"
import { PageContainer } from "../../components/PageContainer/PageContainer.component"
import { JobNameStatus } from "./components/JobNameStatus.component"
import { EstimatedDeliveryTime } from "../../components/EstimatedDeliveryTime/EstimatedDeliveryTime.component"
import { Box, Button } from "@chakra-ui/react"
import { DeliveryLocation } from "../ReviewOrder/components/DeliveryLocation.component"
import { OrderMetaData } from "../../features/order/orderSlice"
import { CartItem } from "../Cart/components/CartItem.component"
import { SimpleDialog } from "../../components/SimpleDialog.component"
import { CartItem as CartItemType } from "../Cart/Cart.types"
import { useSelector } from "react-redux"

// includes a few additional fields from inventory item, needed for CartItem component
type OrderItem = OrderItemRecord & {
  name?: string
  variation?: string
}

export const OrderDetails = () => {
  const { orderID } = useParams()
  const userID = useSelector((state: any) => state.user).userObj?.userId
  const [order, setOrder] = useState<OrderRecord | null>(null)
  const [items, setItems] = useState<OrderItem[] | null>(null)
  const [status, setStatus] = useState<OrderStatusType | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const token = useSelector((state: any) => state.user).token

  // Load and process necessary data on load
  useEffect(() => {
    if (!token) {
      return
    }
    // ensure route has order ID param
    else if (!orderID) {
      navigate("/inventory")
    } else if (userID) {
      if (location.state?.aggregateOrderInfo) {
        setUpState(location.state.aggregateOrderInfo)
      } else {
        OrderService.getOrder(orderID, token)
          .then((res) => {
            const o: AggregateOrderInfo = res
            setUpState(o)
          })
          .catch((err) => {
            console.error(err)
            navigate("/inventory")
          })
      }
    }
  }, [userID, token])

  /**
   * Now that we have all our data from the DB, process it and set up the state vars for the UI
   */
  async function setUpState(o: AggregateOrderInfo) {
    setOrder(o.order ?? null)
    setStatus(o.status ?? null)
    // get item names and variations from corresponding inventory item record
    const itemList = o.items.map((item: OrderItem) => {
      // add name and variation to our item object, so we have it all in one place
      const fullItem: OrderItem = {
        ...item,
        name: item?.InventoryItem?.name ?? "",
        variation: item?.InventoryItem?.variation ?? "",
      }
      return fullItem
    })
    setItems(itemList ?? null)
    setIsLoading(false)
  }

  function orderInfo(): OrderMetaData | null {
    let loc = order?.deliveryLocation
    if (!loc) {
      return null
    }
    return JSON.parse(loc)
  }

  /**
   * Items are not in the cart, but we are reusing the cart item component. Convert the
   * order item object of type OrderItemRecord to an object of type CartItemType (CartItem)
   */
  function toCartItemType(orderItem: OrderItem): CartItemType {
    return {
      category: orderItem?.InventoryItem?.category ?? "",
      price: orderItem?.InventoryItem?.price ?? 0,
      itemId: orderItem.inventoryId,
      name: orderItem?.InventoryItem?.name ?? "",
      variation: orderItem?.InventoryItem?.variation ?? "",
      count: orderItem.quantity,
      imageURL: orderItem?.InventoryItem?.imageURL ?? "",
    }
  }

  function callDeliveryDriver() {
    if (order?.driverPhone) {
      window.open(`tel:${order?.driverPhone}`)
    }
  }

  function showDriverCallBtn() {
    return (
      order?.driverPhone &&
      (status?.status === OrderStatusEnum.Claimed ||
        status?.status === OrderStatusEnum.Loading ||
        status?.status === OrderStatusEnum.Out)
    )
  }

  function cancelOrder(statusID: string | undefined) {
    if (!statusID) return
    setIsCancelModalOpen(false)
    setIsLoading(true)
    OrderService.cancelOrder(statusID, token).then(() => {
      navigate("/inventory")
    })
  }

  function handleRateOrderTap() {
    navigate(`/rate-order/${orderID}`, {
      state: { order: order },
    })
  }

  return (
    <>
      <PageContainer isLoading={isLoading}>
        {!isLoading && (
          <Box m={"1.6rem"}>
            <Box borderBottom={"1px"} borderColor={"gray.300"} pb={"1.6rem"}>
              <JobNameStatus status={status} order={order} />
            </Box>
            <Box borderBottom={"1px"} borderColor={"gray.300"} pb={"1.6rem"}>
              <EstimatedDeliveryTime time={order?.requestedDelivery} />
              <DeliveryLocation orderInformation={orderInfo()} allowChange={false} />
            </Box>
            <Box pb={"1.6rem"}>
              {items &&
                items.map((item) => {
                  return (
                    <CartItem
                      item={toCartItemType(item)}
                      inReview={true}
                      key={item.orderItemId}
                    ></CartItem>
                  )
                })}
            </Box>
            {showDriverCallBtn() && (
              <Button variant={"primary"} w={"100%"} onClick={callDeliveryDriver} mb={"0.8rem"}>
                Call Delivery Driver
              </Button>
            )}
            {status?.orderStatusId &&
              status.status !== OrderStatusEnum.Cancelled &&
              status.status !== OrderStatusEnum.Delivered &&
              status.status !== OrderStatusEnum.NeedsRating && (
                <Button variant={"secondary"} w={"100%"} onClick={() => setIsCancelModalOpen(true)}>
                  Cancel Order
                </Button>
              )}
            {status?.orderStatusId && status.status === OrderStatusEnum.NeedsRating && (
              <Button variant={"primary"} w={"100%"} onClick={handleRateOrderTap}>
                Rate this Order
              </Button>
            )}
          </Box>
        )}
        <SimpleDialog
          text={"Are sure you want to cancel this order?"}
          isOpen={isCancelModalOpen}
          onConfirm={() => cancelOrder(status?.orderStatusId)}
          onClose={() => setIsCancelModalOpen(false)}
        />
      </PageContainer>
    </>
  )
}
