import { ComponentStyleConfig } from "@chakra-ui/react"

export const TextStyles: ComponentStyleConfig = {
  variants: {
    b1: {
      fontSize: "1.8rem",
      fontStyle: "normal",
      lineHeight: "2.2rem",
      fontWeight: 400,
    },
    b1Bold: {
      fontSize: "1.8rem",
      fontStyle: "normal",
      lineHeight: "2.2rem",
      fontWeight: 600,
    },
    b2: {
      fontSize: "1.5rem",
      fontStyle: "normal",
      lineHeight: "1.8rem",
      fontWeight: 400,
    },
    b2Bold: {
      fontSize: "1.5rem",
      fontStyle: "normal",
      lineHeight: "1.8rem",
      fontWeight: 600,
    },
    b3: {
      fontSize: "1.2rem",
      fontStyle: "normal",
      lineHeight: "1.5rem",
      fontWeight: 400,
    },
    b3Bold: {
      fontSize: "1.2rem",
      fontStyle: "normal",
      lineHeight: "1.5rem",
      fontWeight: 600,
    },
    overline: {
      fontsize: "1.2rem",
      fontStyle: "normal",
      lineHeight: "1.5rem",
      fontWeight: 600,
      letterSpacing: "0.32rem",
      fontVariant: "small-caps",
    },
  },
}
