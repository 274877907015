import { Box, Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

export const ViewCartButton = () => {
  const navigate = useNavigate()

  return (
    <Box ml={"1rem"} mr={"1rem"}>
      <Button variant={"primary"} width={"100%"} onClick={() => navigate("/cart")}>
        View Cart
      </Button>
    </Box>
  )
}
