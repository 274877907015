import { Button } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom"
import { OrderRecord, OrderStatusType, OrderStatusEnum } from "../../../services/order.service"
import { Heading } from "../../../components/Heading.component"
import { Text } from "../../../components/Text.component"
import { OrderStatusIndicator } from "../../../components/OrderStatusIndicator.component"

type JobNameStatusProps = {
  order: OrderRecord | null
  status: OrderStatusType | null
}

export function jobName(order: OrderRecord | null) {
  const deliveryLoc = order?.deliveryLocation
  if (deliveryLoc) {
    return JSON.parse(order?.deliveryLocation).name
  } else return ""
}

export const JobNameStatus = ({ order, status }: JobNameStatusProps) => {
  const navigate = useNavigate()

  function isUnclaimed(status: OrderStatusType | null): Boolean {
    return status?.status === OrderStatusEnum.Approved
  }
  return (
    <>
      <Button variant={"link"} onClick={() => navigate(-1)} pb={"1.6rem"}>
        <ArrowBackIcon /> Back
      </Button>
      <Heading type="h3">{jobName(order)}</Heading>
      <OrderStatusIndicator status={status} />
      {isUnclaimed(status) && (
        <Text type="b1" pt={"1.6rem"}>
          Waiting on truck to claim...
        </Text>
      )}
    </>
  )
}
