import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  chakra,
  InputProps as ChakraInputProps,
  Flex,
} from "@chakra-ui/react"

import { Text } from "../../../components/Text.component"

export type ItemAddedModalProps = {
  isOpen: boolean
  onContinueShopping: () => void
  onViewCart: () => void
  cartSize: number
} & ChakraInputProps

export const ItemAddedModal = chakra(
  ({ isOpen, onContinueShopping, onViewCart, cartSize }: ItemAddedModalProps) => {
    return (
      <>
        <Modal isOpen={isOpen} onClose={onContinueShopping}>
          <ModalOverlay />
          <ModalContent w="95%">
            <ModalBody mt={"0.8rem"}>
              <Text type={"b1"} textAlign={"center"}>
                Item added to cart
              </Text>
            </ModalBody>
            <ModalFooter justifyContent={"center"}>
              <Flex direction={"column"}>
                <Button w="100%" onClick={onContinueShopping} variant={"primary"}>
                  Continue Shopping
                </Button>
                <Button w="100%" onClick={onViewCart} variant={"secondary"} mt={"8px"}>
                  View Cart ({cartSize})
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  },
)
