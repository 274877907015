import { Icon, IconProps } from "@chakra-ui/react"

export const DotMenuIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.495C13.242 16.495 14.25 17.503 14.25 18.745C14.25 19.987 13.242 20.995 12 20.995C10.758 20.995 9.75 19.987 9.75 18.745C9.75 17.503 10.758 16.495 12 16.495ZM12 9.745C13.242 9.745 14.25 10.753 14.25 11.995C14.25 13.237 13.242 14.245 12 14.245C10.758 14.245 9.75 13.237 9.75 11.995C9.75 10.753 10.758 9.745 12 9.745ZM12 2.995C13.242 2.995 14.25 4.003 14.25 5.245C14.25 6.487 13.242 7.495 12 7.495C10.758 7.495 9.75 6.487 9.75 5.245C9.75 4.003 10.758 2.995 12 2.995Z"
        fill="black"
      />
    </Icon>
  )
}
