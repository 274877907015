import { extendTheme } from "@chakra-ui/react"
import { fonts } from "./foundations/fonts"
import { textStyles } from "./foundations/textStyles"
import { colors } from "./foundations/colors"
import { ButtonStyles as Button } from "./components/Button.styles"
import { InputStyles as Input } from "./components/Input.styles"
import { HeadingStyles as Heading } from "./components/Heading.styles"
import { TextStyles as Text } from "./components/Text.styles"
import { BadgeStyles as Badge } from "./components/Badge.styles"
import { BottomNavigationStyles as BottomNavigation } from "./components/BottomNavigation.styles"

const spacing = {
  0.5: "0.2rem",
  1: "0.4rem",
  1.5: "0.6rem",
  2: "0.8rem",
  2.5: "1rem",
  3: "1.2rem",
  3.5: "1.4rem",
  4: "1.6rem",
  5: "2.0rem",
  6: "2.4rem",
  7: "2.8rem",
  8: "3.2rem",
  9: "3.6rem",
  10: "4rem",
  12: "4.8rem",
  14: "5.6rem",
  16: "6.4rem",
  20: "8rem",
  24: "9.6rem",
  28: "11.2rem",
  32: "12.8rem",
  36: "14.4rem",
  40: "16rem",
  44: "17.6rem",
  48: "19.2rem",
  52: "20.8rem",
  56: "22.4rem",
  60: "24rem",
  64: "25.6rem",
  72: "28.8rem",
  80: "32rem",
  96: "38.4rem",
}

export const theme = extendTheme({
  fonts,
  textStyles,
  colors,
  components: {
    Button,
    Input,
    Heading,
    Text,
    Badge,
    BottomNavigation,
  },
  // we changed the px/rem ratio in our global stylesheet. change chakra theme vars to account for this.
  fontSizes: {
    xs: "1.2rem",
    sm: "1.4rem",
    md: "1.6rem",
    lg: "1.8rem",
    xl: "2rem",
    "2xl": "2.4rem",
    "3xl": "3rem",
    "4xl": "3.6rem",
    "5xl": "4.8rem",
    "6xl": "6rem",
    "7xl": "7.2rem",
    "8xl": "9.6rem",
    "9xl": "12.8rem",
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": "1.2rem",
    "4": "1.6rem",
    "5": "2rem",
    "6": "2.4rem",
    "7": "2.8rem",
    "8": "3.2rem",
    "9": "3.6rem",
    "10": "4rem",
  },
  space: spacing,
  sizes: {
    ...spacing,
    "3xs": "22.4rem",
    "2xs": "25.6rem",
    xs: "32rem",
    sm: "38.4rem",
    md: "44.8rem",
    lg: "51.2rem",
    xl: "57.6rem",
    "2xl": "67.2rem",
    "3xl": "76.8rem",
    "4xl": "89.6rem",
    "5xl": "102.4rem",
    "6xl": "115.2rem",
    "7xl": "128rem",
    "8xl": "144rem",
  },
  radii: {
    none: "0",
    sm: "0.2rem",
    base: "0.4rem",
    md: "0.2rem",
    lg: "0.8rem",
    xl: "1.2rem",
    "2xl": "1.6rem",
    "3xl": "2.4rem",
    full: "9999px",
  },
})
