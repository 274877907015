import {
  IconButton,
  Button,
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react"
import { Text } from "../../../components/Text.component"
import { CartItem as CartItemType } from "../Cart.types"
import { formatMoney } from "../../../helpers"
import { DotMenuIcon } from "../../../components/icons/DotMenu.icon"
import { useAppDispatch } from "../../../app/hooks"
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../../features/cart/cartSlice"
import { useState } from "react"
import { RemoveItemModal } from "./RemoveItemModal.component"
import { CheckIcon } from "@chakra-ui/icons"

export type CartItemProps = {
  item: CartItemType
  inReview: boolean
}

export const CartItem = (props: CartItemProps) => {
  const dispatch = useAppDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  let item = props.item

  const removeItem = () => {
    onOpen()
  }

  const decreaseQuantity = () => {
    if (item.count == 1) {
      onOpen()
      return
    }
    dispatch(decrementQuantity(item))
  }

  const onConfirmRemove = () => {
    dispatch(removeFromCart(item))
    onClose()
  }

  const onCancelRemove = () => {
    onClose()
  }

  return (
    <>
      <Flex flexDirection={"row"} mt={"1rem"} key={item.itemId}>
        <Image src={item.imageURL} height={"4em"} width={"4em"} mr={"16px"} />
        <Box>
          <Text type="b2Bold">{`${item?.name?.charAt(0)?.toUpperCase()}${item?.name?.slice(
            1,
          )}`}</Text>
          <Text type="b2">{`${item?.variation?.charAt(0)?.toUpperCase()}${item?.variation?.slice(
            1,
          )}`}</Text>
          {!isEditing ? (
            <Text type="b2">x {item.count}</Text>
          ) : (
            <Box>
              <Flex flexDirection={"row"}>
                <Button variant={"secondary"} onClick={() => decreaseQuantity()}>
                  -
                </Button>
                <Box ml={"10px"} mr={"10px"} mt={"5px"}>
                  <Text type="b1">{item.count}</Text>
                </Box>
                <Button variant={"secondary"} onClick={() => dispatch(incrementQuantity(item))}>
                  +
                </Button>
              </Flex>
            </Box>
          )}
        </Box>
        <Spacer />
        {
          <Box mr={"1rem"}>
            <Text type="b2">${formatMoney(item.price * item.count)}</Text>
          </Box>
        }
        {!props.inReview ? (
          !isEditing ? (
            <Box alignItems={"center"}>
              <Menu>
                <MenuButton variant={"ghost"} as={IconButton} icon={<DotMenuIcon />} />
                <MenuList>
                  <MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
                  <MenuItem onClick={() => removeItem()}>Remove</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          ) : (
            <IconButton
              icon={<CheckIcon />}
              onClick={() => setIsEditing(false)}
              variant={"primary"}
              aria-label={""}
              size={"xs"}
            />
          )
        ) : (
          ""
        )}
      </Flex>
      <RemoveItemModal
        isOpen={isOpen}
        onConfirmRemove={onConfirmRemove}
        onCancelRemove={onCancelRemove}
      />
    </>
  )
}
