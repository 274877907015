import { Flex, Spacer, Button } from "@chakra-ui/react"
import { useState } from "react"
import { Heading } from "../../../components/Heading.component"
import { Text } from "../../../components/Text.component"
import { OrderMetaData } from "../../../features/order/orderSlice"
import { EditDeliveryLocationModal } from "./EditDeliveryLocationModal"

type DeliveryLocationProps = {
  orderInformation: OrderMetaData | null
  allowChange?: Boolean
}

export const DeliveryLocation = ({ orderInformation, allowChange }: DeliveryLocationProps) => {
  // set default value
  allowChange = allowChange ?? true

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Flex direction={"row"} mt={"16px"}>
        <Heading type={"h4"}>Delivery Location</Heading>
        <Spacer />
        {allowChange && (
          <Button
            variant={"secondary"}
            size={"sm"}
            onClick={() => {
              setIsOpen(true)
            }}
          >
            Change
          </Button>
        )}
      </Flex>
      <Text type={"b1"} mt={"8px"}>
        {orderInformation?.addressOne}
      </Text>
      {orderInformation?.addressTwo ? <Text type={"b1"}>{orderInformation.addressTwo}</Text> : ""}
      <Text type={"b1"}>{orderInformation?.city + ", OH " + orderInformation?.zip}</Text>
      <EditDeliveryLocationModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        orderInfo={orderInformation}
      />
    </>
  )
}
