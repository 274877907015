import { Icon, IconProps } from "@chakra-ui/react"

export const PackageIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.00204C11.4742 1.00204 10.9577 1.14023 10.5021 1.40273L7.30631 3.22892C7.09618 3.27049 6.90018 3.37903 6.7526 3.54532L3.50386 5.40174L3.5 5.40397C3.13002 5.61758 2.81094 5.90714 2.56308 6.25235C2.50273 6.31272 2.44917 6.38187 2.40439 6.45927C2.36582 6.52594 2.33581 6.59521 2.31402 6.66581C2.10832 7.07928 2.00048 7.53548 2 7.99897V16.001C2.00054 16.5271 2.13941 17.0438 2.40269 17.4993C2.66597 17.9547 3.04439 18.333 3.5 18.596L3.50386 18.5982L10.5 22.596L10.5019 22.5971C10.8191 22.7799 11.1658 22.9025 11.5247 22.9601C11.6661 23.0366 11.828 23.08 12 23.08C12.172 23.08 12.3339 23.0366 12.4752 22.9601C12.8342 22.9025 13.181 22.7799 13.4982 22.5971L13.5 22.596L20.4961 18.5982L20.5 18.596C20.9556 18.333 21.334 17.9547 21.5973 17.4993C21.8606 17.0438 21.9995 16.5271 22 16.001V7.99897C21.9995 7.53547 21.8917 7.07926 21.686 6.66579C21.6642 6.5952 21.6342 6.52594 21.5956 6.45927C21.5508 6.38187 21.4973 6.31273 21.4369 6.25236C21.1891 5.90715 20.87 5.61758 20.5 5.40396L13.5 1.40397L13.4979 1.40276C13.0424 1.14024 12.5258 1.00204 12 1.00204ZM13 20.5782L19.5 16.864L19.5016 16.8631C19.6527 16.7754 19.7783 16.6497 19.8658 16.4984C19.9535 16.3466 19.9998 16.1743 20 15.999V8.53753L13 12.5868V20.5782ZM11 12.5868L4 8.53753V15.9994C4.00025 16.1746 4.04654 16.3467 4.13423 16.4984C4.22169 16.6497 4.34726 16.7754 4.49842 16.8631L4.5 16.864L11 20.5782V12.5868ZM12.5 3.13603L18.961 6.82803L16.5052 8.24863L9.5609 4.24409L11.4961 3.13825L11.5 3.13602C11.652 3.04825 11.8245 3.00204 12 3.00204C12.1755 3.00204 12.348 3.04826 12.5 3.13603ZM12 10.8547L14.5065 9.40479L7.54999 5.39318L5.03898 6.82803L12 10.8547Z"
        fill="currentColor"
      />
    </Icon>
  )
}
