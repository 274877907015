import { ComponentStyleConfig } from "@chakra-ui/react"
import { textStyles } from "../foundations/textStyles"

export const InputStyles: ComponentStyleConfig = {
  baseStyle: {
    field: {
      _placeholder: {
        color: "gray.400",
        ...textStyles.b2Regular,
      },
    },
  },
  variants: {
    filled: {
      field: {
        bg: "gray.50",
        border: "1px solid",
        borderColor: "inherit",
      },
    },
  },
  defaultProps: {
    focusBorderColor: "blue.300",
    errorBorderColor: "red.400",
    variant: "filled",
  },
}
