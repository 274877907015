import { withAuthenticationRequired } from "@auth0/auth0-react"
import { PageContainer } from "../components/PageContainer/PageContainer.component"

export const ProtectedRoute = (component: any) => {
  const Comp = withAuthenticationRequired(component.component, {
    onRedirecting() {
      return <PageContainer isLoading={true} />
    },
  })
  return <Comp />
}
