import { PageContainer } from "../../components/PageContainer/PageContainer.component"
import { OrderHistory as OrderHistoryOrdersState } from "./components/OrderHistory.component"
import { OrderHistoryEmptyState } from "./components/OrderHistoryEmptyState.component"
import { AggregateOrderInfo, OrderService, OrderStatusEnum } from "../../services/order.service"
import { useAppSelector } from "../../app/hooks"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export const OrderHistory = (props: any) => {
  const [orderHistory, setOrderHistory] = useState(new Array<AggregateOrderInfo>())
  const [areOrdersLoading, setAreOrdersLoading] = useState(false)
  const userID = useAppSelector((state) => state.user).userObj?.userId
  const token = useSelector((state: any) => state.user).token

  useEffect(() => {
    setAreOrdersLoading(true)
    if (userID) {
      OrderService.getOrdersByUser(userID, token)
        .then((aggregateOrderInfoList) => {
          if (aggregateOrderInfoList) {
            aggregateOrderInfoList = aggregateOrderInfoList.filter((item) => {
              return (
                item.status !== null &&
                (item.status.status === OrderStatusEnum.Cancelled ||
                  item.status.status === OrderStatusEnum.Delivered ||
                  item.status.status === OrderStatusEnum.NeedsRating ||
                  item.status.status === OrderStatusEnum.Rejected)
              )
            })
          }
          setOrderHistory(aggregateOrderInfoList)
        })
        .finally(() => {
          setAreOrdersLoading(false)
        })
    }
  }, [userID])

  const displayHistory = () => {
    return (
      <>
        {!areOrdersLoading && orderHistory.length > 0 && (
          <OrderHistoryOrdersState aggregateOrderInfo={orderHistory} />
        )}
        {!areOrdersLoading && orderHistory.length === 0 && <OrderHistoryEmptyState />}
      </>
    )
  }

  return <PageContainer isLoading={areOrdersLoading}>{displayHistory()}</PageContainer>
}
