import { defineStyleConfig } from "@chakra-ui/react"
import { textStyles } from "../foundations/textStyles"

export const BottomNavigationStyles = defineStyleConfig({
  baseStyle: {
    container: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      py: "0.25rem",
      bottom: 0,
      left: 0,
      right: 0,
      bg: "black-and-white.white",
      height: "4.5rem",
    },
    item: {
      flex: 1,
      mx: 4,
      margin: 0,
      _selected: {
        borderTop: "1px solid",
        borderColor: "blue.800",
      },
    },
    label: { color: "blue.800", ...textStyles.b3Bold },
  },
})
