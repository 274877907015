import axios from "axios"
import { InventoryItem } from "../pages/Inventory/Inventory.types"

const apiUrl = process.env.REACT_APP_API_HOST

const getInventory = async (token: string): Promise<Array<InventoryItem>> => {
  return (
    await axios.get(`${apiUrl}/full-inventory`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data
}

export const InventoryService = { getInventory }
