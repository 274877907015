import { createSlice } from "@reduxjs/toolkit"
import { User as Auth0User } from "@auth0/auth0-react"

interface UserState {
  auth0Obj: Auth0User | null
  userObj: User | null
  org: Org | null
  userType: UserType | null
  token: String | null
  fcmToken: String | null
}

export interface User {
  userId?: string
  auth0Id: string
  lastName: string
  firstName: string
  email: string
  phone?: string
  picture?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
  fcmTokens?: Array<string>
}

export interface Org {
  orgId: string
  name: string
  emailUser?: string
  locations?: Array<JSON>
  picture?: string
}

export interface UserType {
  agentId?: string
  driverId?: string
}

const initialState: UserState = {
  auth0Obj: null,
  userObj: null,
  org: null,
  userType: null,
  token: null, // Auth0 token
  fcmToken: null, // device token for Firebase Cloud Messaging (push notifications)
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setUser: (state, action) => {
      state.userObj = action.payload
    },
    setAuth0: (state, action) => {
      state.auth0Obj = action.payload
    },
    setOrg: (state, action) => {
      state.org = action.payload
    },
    setUserType: (state, action) => {
      state.userType = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setFCMToken: (state, action) => {
      state.fcmToken = action.payload
    },
    clearUser: (state) => {
      state = initialState
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setAuth0, setOrg, setUserType, setToken, setFCMToken, clearUser } =
  userSlice.actions

export default userSlice.reducer
