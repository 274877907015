import { Flex, Text, Image } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import FeazelOrgImg from "../../../assets/images/feazel-org.svg"
import { LoadingScreen } from "../../../components/LoadingScreen.component"
import { AgentsService } from "../../../services/agents.service"

type OrgInfoProps = {
  orgID: string
}

export const OrgInfo = ({ orgID }: OrgInfoProps) => {
  /** temporary: static Feazel org content. @todo - fetch org info from DB. */
  const orgImg = FeazelOrgImg
  const orgTitle = "Feazel Roofing"
  const feazelOrgId = "5d25b095-aafd-48fd-82bf-25ebd9c3e2ac"
  const token = useSelector((state: any) => state.user).token
  const [numMembers, setNumMembers] = useState(0)
  const [loadingNumMembers, setLoadingNumMembers] = useState(true)

  useEffect(() => {
    setLoadingNumMembers(true)
    AgentsService.getAgentsByOrg(feazelOrgId, token).then((res) => {
      setNumMembers(res.data)
      setLoadingNumMembers(false)
    })
  }, [])

  return (
    <>
      <Flex direction={"column"} alignItems={"center"} rowGap={"0.8rem"} pb="3.4rem">
        <Image boxSize="8rem" src={orgImg} alt="Feazel Roofing" />
        <Text fontWeight={"600"} fontSize={"1.8rem"}>
          {orgTitle}
        </Text>
        {loadingNumMembers ? (
          <LoadingScreen />
        ) : (
          <Text fontSize={"1.5rem"}>{numMembers} members</Text>
        )}
      </Flex>
    </>
  )
}
