export const colors: { [key: string]: { [key: string]: string } } = {
  "black-and-white": {
    black: "#000000",
    white: "#FFFFFF",
  },
  blue: {
    800: "#263B5E",
    600: "#405F92",
    400: "#5C84C7",
    300: "#6c98e1",
    200: "#9EB7E1",
  },
  green: {
    800: "#38460D",
    600: "#516614",
    400: "#7D992A",
    300: "#B3CE62",
    100: "#E4FF94",
  },
  "inputs-green": {
    900: "#7D992A",
    800: "#7D992A",
    700: "#7D992A",
    600: "#7D992A",
    500: "#7D992A",
    400: "#7D992A",
    300: "#7D992A",
    200: "#7D992A",
    100: "#7D992A",
    50: "#CBD5E0",
  },
  red: {
    400: "#F56565",
  },
  gray: {
    900: "#171923",
    800: "#1A202C",
    700: "#2D3748",
    600: "#4A5568",
    500: "#718096",
    400: "#A0AEC0",
    300: "#CBD5E0",
    200: "#E2E8F0",
    100: "#EDF2F7",
    50: "#F7FAFC",
  },
}
