import { defineStyleConfig } from "@chakra-ui/react"
import { textStyles } from "../foundations/textStyles"

export const ButtonStyles = defineStyleConfig({
  baseStyle: {
    color: "black-and-white.white",
    borderRadius: "0.2rem",
  },
  sizes: {
    large: {
      height: "3.8rem",
      padding: "0.8rem 1.6rem",
      ...textStyles.b1Bold,
    },
    medium: {
      height: "3.4rem",
      padding: "0.8rem 1.2rem",
      ...textStyles.b2Bold,
    },
    small: {
      height: "3.1rem",
      padding: "0.8rem",
      ...textStyles.b3Bold,
    },
  },
  variants: {
    primary: {
      bg: "blue.400",
      _hover: {
        _disabled: {
          bg: "blue.400"
        },
        bg: "blue.600",
      },
      _focus: {
        bg: "blue.800",
      },
      _disabled: {
        _hover: {
          bg: "blue.400",
        },
        bg: "blue.400",
        opacity: "0.45",
      },
    },
    secondary: {
      bg: "gray.50",
      color: "gray.500",
      border: "0.1rem solid",
      borderColor: "gray.400",
      _hover: {
        bg: "gray.200",
      },
      _focus: {
        bg: "gray.300",
      },
      _disabled: {
        bg: "gray.50",
        opacity: "0.45",
      },
    },
    link: {
      color: "gray.500",
      fontSize: "1.2rem",
      fontWeight: "600",
      textDecorationLine: "underline",
      _hover: {
        color: "gray.700",
      },
      _focus: {
        color: "gray.900",
      },
    },
  },
  defaultProps: {
    variant: "primary",
    size: "medium",
  },
})
