import { ChakraProvider } from "@chakra-ui/react"
import { theme } from "./theme/theme"
import { AppRouter } from "./router/AppRouter"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

const queryClient = new QueryClient()

export const App = () => (
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </ChakraProvider>
)
