import { Flex, Box, Button, useDisclosure, useToast } from "@chakra-ui/react"
import { Heading } from "../../../components/Heading.component"
import { Input } from "../../../components/Input.component"
import { SimpleModal } from "../../../components/SimpleModal.component"
import React, { useState } from "react"
import { InviteService } from "../../../services/invite.service"

type InviteCodeProps = {
  setInviteCode: (type: string) => void
  inviteCode: string
  submitCode: () => void
  incrementProgress: () => void
}

export const InviteCode = ({
  setInviteCode,
  inviteCode,
  submitCode,
  incrementProgress,
}: InviteCodeProps) => {
  const toast = useToast()

  const submit = async () => {
    try {
      await InviteService.getInvite(inviteCode)
      incrementProgress()
      submitCode()
    } catch (err: any) {
      // show message
      toast({
        title: "Invalid Invite Code",
        description:
          err?.response?.status === 400
            ? "The code you entered has already been used. Please contact your administrator to receive a new code."
            : "The invite code you entered was invalid. Please double-check your code for mistakes and re-enter it.",
        position: "top",
        status: "error",
        isClosable: true,
        duration: null,
      })
    }
  }

  // for opening/closing the "I don't have a code" modal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const modalBodyContent = "Please contact your administrator for an invite code to your business."

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  function inputOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const code = event.target.value
    setInviteCode(code)
    setInviteCode(code)
    setIsSubmitDisabled(code.includes("_") || code === "XXX-XXX-XXXX" || code === "")
  }

  return (
    <>
      <Flex direction={"column"}>
        <Box>
          <Heading type="h2" pt={"3.2rem"} pb={"1.6rem"}>
            Enter your invite code
          </Heading>
          <Input
            inputPlaceholderText="********-****-****-****-************"
            inputIsInvalid={false}
            onChange={(event) => inputOnChange(event)}
          ></Input>
          <Button variant={"link"} onClick={onOpen}>
            I don't have a code
          </Button>
        </Box>
        <Button w="100%" mt={"1.6rem"} onClick={() => submit()} isDisabled={isSubmitDisabled}>
          Continue
        </Button>
      </Flex>
      <SimpleModal bodyContent={modalBodyContent} isOpen={isOpen} onClose={onClose} />
    </>
  )
}
