import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Box, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Heading } from "../../../components/Heading.component";
import { Input } from "../../../components/Input.component";

type ProfileProps = {
  incrementProgress: () => void
}

export const ProfileDetails = ({ incrementProgress }: ProfileProps) => {
  const { user } = useAuth0()
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contBtnDisabled, setContBtnDisabled] = useState(true)

  const updateProfileDetails = () => {
    window.localStorage.setItem("profile-fName", firstName)
    window.localStorage.setItem("profile-lName", lastName)
    window.localStorage.setItem("profile-phone", phoneNumber)
    incrementProgress()
  }

  useEffect(() => {
    if (firstName && lastName && validPhone(phoneNumber)) {
      setContBtnDisabled(false)
    } else {
      setContBtnDisabled(true)
    }
  }, [firstName, lastName, phoneNumber, user])

  const validPhone = (phone: string | undefined) => {
    const PHONE_REGEX = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/gmi);
    if(phone){
      return PHONE_REGEX.test(phone);
    }
    return false
  }

  return (
    <>
      <Flex direction="column" w="100%">
        <Heading type="h2" pt="3.2rem">
          Tell us about yourself:
        </Heading>
        <Flex direction="row" w="100%">
          <Box padding="2px">
            <Input
              inputIsInvalid={false}
              inputPlaceholderText={"First Name"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            />
          </Box>
          <Box padding="2px">
            <Input
              inputIsInvalid={false}
              inputPlaceholderText={"Last Name"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            />
          </Box>
        </Flex>
        <Box pt="1px">
          <Input
            inputIsInvalid={false}
            inputPlaceholderText={"Phone Number"}
            type="tel"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
          />
        </Box>
        <Box>
          <Button
          variant="primary"
          width="100%"
          disabled={contBtnDisabled}
          onClick={updateProfileDetails}
          >Continue</Button>
        </Box>
      </Flex>
    </>
  )
}