import { Flex } from "@chakra-ui/react"

type CardProps = {
  children: React.ReactNode
}

export const Card = ({ children }: CardProps) => {
  return (
    <>
      <Flex
        borderRadius={"0.4rem"}
        bg="white"
        border={"0.1px solid"}
        borderColor={"gray.300"}
        p="0.8rem"
        boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.05)"}
      >
        {children}
      </Flex>
    </>
  )
}
