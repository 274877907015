import { useState } from "react"
import { Carousels } from "./Carousels.component"
import { SingleItem } from "./SingleItem.component"
import { Box } from "@chakra-ui/react"
import { InventoryGutsProps, InventoryItem } from "../Inventory.types"

export const InventoryGuts = (props: InventoryGutsProps) => {
  const [renderSingleItem, setRenderSingleItem] = useState(false)
  const [itemSelected, setItemSelected] = useState<InventoryItem>({
    category: "",
    price: 0,
    itemId: "",
    name: "",
    variation: "",
    imageURL: "",
  })
  const [correlatedItems, setCorrelatedItems] = useState<InventoryItem[]>([])

  const onBack = () => {
    setRenderSingleItem(false)
    props.setSearchString("")
  }

  const singleItemClicked = (item: InventoryItem, correlatedItems: InventoryItem[]) => {
    setRenderSingleItem(true)
    setItemSelected(item)
    setCorrelatedItems(correlatedItems)
  }

  return (
    <Box>
      {renderSingleItem ? (
        <SingleItem
          item={itemSelected}
          correlatedItems={correlatedItems}
          onBackClick={onBack}
          navigateToSingleItem={singleItemClicked}
        />
      ) : (
        <Carousels
          setSearchString={props.setSearchString}
          inventoryItems={props.inventoryItems}
          onItemClick={singleItemClicked}
        />
      )}
    </Box>
  )
}
