import { createSlice } from "@reduxjs/toolkit"

interface OrderState {
  orderInformation: OrderMetaData | null
}

export interface OrderMetaData {
  jobCode: string
  addressOne: string
  addressTwo: string
  city: string
  zip: string
}

const initialOrderMetaData: OrderMetaData = {
  jobCode: "",
  addressOne: "",
  addressTwo: "",
  city: "",
  zip: "",
}

const initialState: OrderState = {
  orderInformation: { ...initialOrderMetaData },
}

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateOrderInformation: (state, action) => {
      state.orderInformation = action.payload
    },
    clearOrderInfo: (state) => {
      state.orderInformation = initialOrderMetaData
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateOrderInformation, clearOrderInfo } = orderSlice.actions

export default orderSlice.reducer
