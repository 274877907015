import { Flex, Box, Button } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { Input } from "../../../components/Input.component"
import { useNavigate } from "react-router-dom"
import { SimpleDialog } from "../../../components/SimpleDialog.component"
import { useState } from "react"
import { useAppDispatch } from "../../../app/hooks"
import { clearCart } from "../../../features/cart/cartSlice"
import { clearOrderInfo } from "../../../features/order/orderSlice"

export const SearchBar = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isClearModalOpen, setIsClearModelOpen] = useState(false)

  return (
    <>
      <SimpleDialog
        text={"This will clear your order, are you sure you want to go back?"}
        isOpen={isClearModalOpen}
        onConfirm={() => {
          dispatch(clearCart())
          dispatch(clearOrderInfo())
          navigate("/inventory")
        }}
        onClose={() => setIsClearModelOpen(false)}
      />
      <Flex mt={"0.625rem"} ml={"1rem"} mr={"1rem"} direction={"column"}>
        <Box>
          <Button variant={"link"} onClick={() => setIsClearModelOpen(true)}>
            <ArrowBackIcon /> Back
          </Button>
        </Box>
        <Box>
          <Input
            labelText={""}
            inputIsInvalid={false}
            inputPlaceholderText={"Search for an item"}
            helperText={""}
            onChange={(event) => {
              props.setSearchString(event.target.value)
            }}
            search={true}
          />
        </Box>
      </Flex>
    </>
  )
}
