import { Box, Flex, Image } from "@chakra-ui/react"
import { Heading } from "../../../components/Heading.component"
import { Text } from "../../../components/Text.component"
import { ViewCartButton } from "./ViewCartButton.component"
import { SearchBar } from "./SearchBar.component"
import { CarouselsProps, InventoryItem } from "../Inventory.types"

export const Carousels = (props: CarouselsProps) => {
  return (
    <>
      <SearchBar setSearchString={props.setSearchString} />
      <Box>
        {props.inventoryItems.length === 0 ? (
          <Flex
            flex="1"
            backgroundColor={"gray.50"}
            flexDir="column"
            justifyContent={"center"}
            alignItems={"center"}
            height={"100vh"}
          >
            <Box mb="4rem">
              <Text pb="2.4rem" textAlign={"center"}>
                No Inventory Found
              </Text>
            </Box>
          </Flex>
        ) : (
          props.inventoryItems.map((value) => {
            return (
              <Box key={value.category}>
                <Heading ml={"1rem"} variant={"h4"}>
                  {value.category.charAt(0).toUpperCase() + value.category.slice(1)}
                </Heading>
                {
                  // RENDER ITEMS GROUPED BY SUBCATEGORY
                  value.subcategories &&
                    value.subcategories.map((subcategory) => {
                      const correlatedItems = subcategory.items.filter((correlatedItem) => {
                        return correlatedItem.category === value.category
                      })
                      return (
                        <Box key={subcategory.name}>
                          <Heading type="h5" ml={"1rem"}>
                            {subcategory.name}
                          </Heading>
                          <Box overflow={"auto"} whiteSpace={"nowrap"}>
                            {subcategory.items.map((individualItem) => (
                              <Item
                                onItemClick={props.onItemClick}
                                individualItem={individualItem}
                                correlatedItems={correlatedItems}
                                key={individualItem.itemId}
                              />
                            ))}
                          </Box>
                        </Box>
                      )
                    })
                }
                {
                  // RENDER ITEMS GROUPED BY CATEGORY
                  value.items && (
                    <Box overflow={"auto"} whiteSpace={"nowrap"}>
                      {value.items.map((individualItem) => {
                        const correlatedItems = value.items!.filter((correlatedItem) => {
                          return correlatedItem.category === individualItem.category
                        })
                        return (
                          <Item
                            onItemClick={props.onItemClick}
                            individualItem={individualItem}
                            correlatedItems={correlatedItems}
                            key={individualItem.itemId}
                          />
                        )
                      })}
                    </Box>
                  )
                }
              </Box>
            )
          })
        )}
        <ViewCartButton />
      </Box>
    </>
  )
}

interface ItemProps {
  onItemClick: Function
  individualItem: InventoryItem
  correlatedItems: InventoryItem[]
}

const Item = (props: ItemProps) => {
  return (
    <Box
      display={"inline-block"}
      padding={"1.4rem"}
      onClick={() => props.onItemClick(props.individualItem, props.correlatedItems)}
      key={props.individualItem.itemId + props.individualItem.variation}
    >
      <Flex direction={"column"}>
        <Image width={"120px"} height={"120px"} src={props.individualItem.imageURL} />
        <Text type={"b2Bold"}>
          {`${props.individualItem?.name
            ?.charAt(0)
            .toUpperCase()}${props.individualItem?.name?.slice(1)}`}
        </Text>
        <Text type={"b2"}>
          {`${props.individualItem?.variation
            ?.charAt(0)
            ?.toUpperCase()}${props.individualItem?.variation?.slice(1)}`}
        </Text>
      </Flex>
    </Box>
  )
}
