import { Flex, Button } from "@chakra-ui/react"
import { Heading } from "../../../components/Heading.component"
import { OrgInfo } from "./OrgInfo"
import { useAuth0 } from "@auth0/auth0-react"

type OrganizationProps = {
  organizationID: string
  inviteCode: string
  incrementProgress: () => void
}

export const OrganizationVerification = ({
  organizationID,
  inviteCode,
  incrementProgress,
}: OrganizationProps) => {
  const { loginWithRedirect } = useAuth0()

  return (
    <>
      <Flex direction={"column"}>
        <Heading type="h2" pt="3.2rem" pb={"1.6rem"} textAlign={"center"}>
          You are about to join
        </Heading>
        <OrgInfo orgID={organizationID} />
        <Button
          w="100%"
          onClick={() => {
            incrementProgress()
            {
              /*
               * Pass state to restore after redirecting from Auth0 back to the app. This will allow us to tell the difference
               * between a user authenticating for the first time (and needs a PG user object) and an existing user just signing in.
               */
            }
            window.localStorage.setItem("invite-code", inviteCode)
            loginWithRedirect({
              screen_hint: "signup",
              appState: { inviteCode: inviteCode },
            })
          }}
        >
          Continue
        </Button>
      </Flex>
    </>
  )
}
