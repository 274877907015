import axios from "axios"
import { CartItem } from "../pages/Cart/Cart.types"
import { InventoryItem } from "../pages/Inventory/Inventory.types"

const apiUrl = process.env.REACT_APP_API_HOST

export type FullOrderInfo = {
  orderInformation: {
    jobCode: string
    addressOne: string
    addressTwo: string
    city: string
    zip: string
  }
  orgId: string
  orderedById: string
  requestedDelivery: Date
  comments: string
  orderedItems: Array<CartItem>
}

export type OrderRecord = {
  orderId: string
  orgId: string
  orderedBy: string
  approvedBy: string
  claimedBy: string
  agentFeedbackId: string
  deliveryLocation: string
  requestedDelivery: Date
  comments: string
  purchaseOrderNumber: string
  deliveredAt: Date
  deliveredImageUri: string
  orderDroppedPerson?: string
  orderDroppedLocation?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
  driverPhone?: string
}

export type OrderItemRecord = {
  orderItemId: string
  orderId: string
  inventoryId: string
  quantity: number
  InventoryItem?: InventoryItem
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
}

export type OrderStatusType = {
  orderStatusId: string
  estimatedDeliveryAt: Date
  status: OrderStatusEnum
}

export enum OrderStatusEnum {
  Awaiting = "Awaiting Approval",
  Approved = "Approved",
  Claimed = "Claimed by Driver",
  Loading = "Loading Shipment",
  Out = "Out for Delivery",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
  NeedsRating = "Needs Rating",
  Rejected = "Rejected",
}

const placeOrder = async (fullOrderInfo: FullOrderInfo, token: string) => {
  return await axios.post(`${apiUrl}/order`, fullOrderInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export type AggregateOrderInfo = {
  order: OrderRecord
  items: OrderItemRecord[]
  status: OrderStatusType
}
/**
 * Retreives the orders that have by ordered by a particular user
 * @param userID string
 */
const getOrdersByUser = async (
  userID: string,
  token: string,
): Promise<Array<AggregateOrderInfo>> => {
  let data = (
    await axios.get(`${apiUrl}/orders-by-user/${userID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data
  // sort by creation date, newest to oldest
  sortOrdersByDate(data)
  return data
}

/**
 * Retrieves a single order by an ID
 * @param orderID
 */
const getOrder = async (orderID: string, token: string): Promise<AggregateOrderInfo> => {
  return (
    await axios.get(`${apiUrl}/order/${orderID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data
}

/**
 * Set order status to "cancelled"
 */
const cancelOrder = async (orderStatusID: string, token: string): Promise<OrderStatusType> => {
  return await axios.post(
    `${apiUrl}/cancel-order`,
    { orderStatusId: orderStatusID },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

/**
 * Gets all orders Awaiting Approval
 */
const getAwaitingApprovals = async (token: string): Promise<Array<AggregateOrderInfo>> => {
  let data = (
    await axios.get(`${apiUrl}/get-orders-awaiting-approval`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data
  // sort by creation date, newest to oldest
  sortOrdersByDate(data)
  return data
}

/**
 * Update order status
 */
const updateOrderStatus = async (
  orderId: string,
  newOrderStatus: OrderStatusEnum,
  approverId: string | null,
  token: string,
): Promise<any> => {
  return await axios.put(
    `${apiUrl}/update-order-status`,
    {
      orderId: orderId,
      orderStatus: newOrderStatus,
      approverId: approverId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

/**
 * Helper. Sort the orders so most recent appears at top
 */
function sortOrdersByDate(aggOrderInfoList: AggregateOrderInfo[]) {
  aggOrderInfoList?.sort((a, b) => {
    return new Date(b.order.createdAt ?? 0).valueOf() - new Date(a.order.createdAt ?? 0).valueOf()
  })
}

export const OrderService = {
  placeOrder,
  getOrdersByUser,
  getOrder,
  cancelOrder,
  getAwaitingApprovals,
  updateOrderStatus,
}
