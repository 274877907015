import { PageContainer } from "../../components/PageContainer/PageContainer.component"
import { Flex, Box, Button } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { Input } from "../../components/Input.component"
import { Heading } from "../../components/Heading.component"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"
import { updateOrderInformation } from "../../features/order/orderSlice"
import { clearCart } from "../../features/cart/cartSlice"
import { EstimatedDeliveryTime } from "../../components/EstimatedDeliveryTime/EstimatedDeliveryTime.component"

export const StartOrder = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [shopBtnDisabled, setShopBtnDisabled] = useState(true)
  const [jobCode, setJobCode] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [city, setCity] = useState("")
  const [zip, setZip] = useState("")

  useEffect(() => {
    setShopBtnDisabled(true)
    if (jobCode && addressLine1 && city && zip) {
      setShopBtnDisabled(false)
    }
  }, [jobCode, addressLine1, city, zip])

  const onShopClick = () => {
    const orderInformation = {
      jobCode: jobCode,
      addressOne: addressLine1,
      addressTwo: addressLine2,
      city: city,
      zip: zip,
    }
    dispatch(updateOrderInformation(orderInformation))
    dispatch(clearCart())
    navigate("/inventory")
  }

  return (
    <PageContainer>
      <Flex mt={"0.625rem"} ml={"1rem"} mr={"1rem"} direction={"column"}>
        <Flex direction={"column"} borderBottom={"1px"} borderColor={"gray.300"}>
          <Box>
            <Button variant={"link"} onClick={() => navigate("/inventory")}>
              <ArrowBackIcon /> Back
            </Button>
          </Box>
          <Box mt={"16px"}>
            <Input
              inputIsInvalid={false}
              inputPlaceholderText={"Job name or number"}
              labelText={"Designation"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setJobCode(event.target.value)
              }
            />
          </Box>
        </Flex>
        <Flex direction={"column"} mt={"16px"} borderBottom={"1px"} borderColor={"gray.300"}>
          <Heading type={"h5"}>Delivery Location</Heading>
          <Input
            inputIsInvalid={false}
            inputPlaceholderText={""}
            labelText={"Address Line 1"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAddressLine1(event.target.value)
            }
          />
          <Input
            inputIsInvalid={false}
            inputPlaceholderText={""}
            labelText={"Address Line 2 (Optional)"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAddressLine2(event.target.value)
            }
          />
          <Flex direction={"row"}>
            <Box w={"200px"} mr={"16px"}>
              <Input
                inputIsInvalid={false}
                inputPlaceholderText={""}
                labelText={"City"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCity(event.target.value)
                }
              />
            </Box>

            <Input
              inputIsInvalid={false}
              inputPlaceholderText={""}
              labelText={"ZIP / Postal Code"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setZip(event.target.value)}
              type="number"
            />
          </Flex>
        </Flex>
        <EstimatedDeliveryTime />
        <Box mt={"1rem"}>
          <Button
            variant={"primary"}
            width={"100%"}
            disabled={shopBtnDisabled}
            onClick={onShopClick}
          >
            Shop for Materials
          </Button>
        </Box>
      </Flex>
    </PageContainer>
  )
}
