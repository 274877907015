import { PageContainer } from "../../components/PageContainer/PageContainer.component"
import { Flex, Box, Button, Checkbox } from "@chakra-ui/react"
import { ArrowBackIcon, CopyIcon } from "@chakra-ui/icons"
import { Heading } from "../../components/Heading.component"
import { useNavigate } from "react-router-dom"
import { Input } from "../../components/Input.component"
import { useState } from "react"
import { InviteService } from "../../services/invite.service"
import { useSelector } from "react-redux"
import { SentInvites } from "./components/SentInvites.component"

export const InviteUsers = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [reRenderList, setReRenderList] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState({})
  const [error, setError] = useState({})
  const token = useSelector((state: any) => state.user).token

  const user = useSelector((state: any) => state.user)

  const validEmail = () => {
    return String(email)
      .toLowerCase()
      .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  }

  const submitInvite = async () => {
    try {
      setIsLoading(true)
      const result = await InviteService.createInvite(email, isAdmin, user.userObj.auth0Id, token)
      setSuccess({ title: "Invite Sent", description: `An email was sent to ${email}` })
      setEmail("")
      setIsAdmin(false)
      setReRenderList(!reRenderList)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      setError({ title: "Error", description: err })
      throw err
    }
  }

  return (
    <PageContainer isLoading={isLoading} success={success} error={error}>
      <Flex flex="1" mt={"0.625rem"} ml={"1rem"} mr={"1rem"} flexDir="column">
        <Box>
          <Button variant={"link"} onClick={() => navigate("/inventory")}>
            <ArrowBackIcon /> Back
          </Button>
        </Box>
        <Box pb={"2rem"}>
          <Heading type="h2" mt={"2rem"}>
            Invite Team Members
          </Heading>
        </Box>
        <Box pb={"2rem"}>
          <Input
            type="text"
            inputIsInvalid={false}
            inputPlaceholderText="ex. johnSmith@email.com"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <Checkbox
            size={"md"}
            pb={"2.4rem"}
            isChecked={isAdmin}
            onChange={(event) => setIsAdmin(event.target.checked)}
          >
            Has Admin Privileges
          </Checkbox>
          <Button
            variant={"primary"}
            w={"100%"}
            isDisabled={!validEmail()}
            onClick={() => submitInvite()}
          >
            Send Invite
          </Button>
        </Box>
        <SentInvites reRenderList={reRenderList} />
      </Flex>
    </PageContainer>
  )
}
