import { Flex, Button, Image } from "@chakra-ui/react"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"
import EntregaLogo from "../../assets/Style=Default.svg"
import { LoadingScreen } from "../../components/LoadingScreen.component"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export const Splash = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0()
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.user).token

  useEffect(() => {
    if (isAuthenticated && token) {
      navigate("/inventory")
    }
  }, [navigate, isAuthenticated, token])

  return (
    <Flex height={"100vh"} flexDir="column" p="1.6rem">
      {isLoading && !user && <LoadingScreen />}
      {!isLoading && !user && (
        <Flex flex="1" flexDir="column" justifyContent={"center"} alignItems={"center"}>
          <Image src={EntregaLogo} w="55%" mb="4rem" />
          <Button variant={"primary"} w="100%" onClick={() => navigate("/create-user")}>
            Create an Account
          </Button>
          <Button
            variant={"secondary"}
            w="100%"
            mt="0.8rem"
            onClick={() => loginWithRedirect({ appState: {} })}
          >
            Sign In
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
