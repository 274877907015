import { useEffect, useState } from "react"
import { InventoryGuts } from "./components/InventoryGuts.component"
import { OrdersInProgressEmptyState } from "./components/OrdersInProgressEmptyState.component"
import { OrdersInProgress } from "./components/OrdersInProgress.component"
import { InventoryItem, ItemInventoryByCategory, Subcategory } from "./Inventory.types"
import { useLocation, useNavigate } from "react-router-dom"
import { PageContainer } from "../../components/PageContainer/PageContainer.component"
import { useSelector, useDispatch } from "react-redux"
import { AggregateOrderInfo, OrderService, OrderStatusEnum } from "../../services/order.service"
import { useAppSelector } from "../../app/hooks"
import { setUser } from "../../features/user/userSlice"
import { UserService } from "../../services/user.service"
import { PushNotificationService } from "../../services/push-notification-service"
import { useGetInventory } from "./hooks/useGetInventory"

export const Inventory = () => {
  const token = useSelector((state: any) => state.user).token
  const { data: invData, isFetching } = useGetInventory(token)
  const [inventoryItems, setInventoryItems] = useState<ItemInventoryByCategory[]>([])
  const [filteredInventory, setFilteredInventory] = useState<ItemInventoryByCategory[]>([])
  const [searchString, setSearchString] = useState("")
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentOrderInfo = useSelector((state: any) => state.order.orderInformation)

  function getInventoryItems() {
    let tempInventoryItems: ItemInventoryByCategory[] = []
    // group inventory items by category (and subcategory, if applicable)
    invData?.forEach((value) => {
      let foundCategory = false
      // check to see if a category grouping for this item already exists
      tempInventoryItems.forEach((inventoryCategory) => {
        // if the category grouping does exist, add this item to it
        if (inventoryCategory.category === value.category) {
          // the category may have subcategories for more specific grouping
          if (value.subcategory) {
            // find this item's subcategory
            const subcat = inventoryCategory?.subcategories?.find(
              (sub) => sub.name === value.subcategory,
            )
            if (inventoryCategory.subcategories && subcat) {
              subcat.items.push(value) // add item to subcat grouping
            } else if (inventoryCategory.subcategories) {
              // if it doesn't exist, add new subcategory
              inventoryCategory.subcategories.push({ name: value.subcategory, items: [value] })
            } else {
              // create subcategory property
              inventoryCategory.subcategories = [{ name: value.subcategory, items: [value] }]
            }
          } else if (inventoryCategory.items) {
            inventoryCategory.items.push(value)
          }
          foundCategory = true
        }
      })
      // handle if a category grouping doesn't exist yet. create a new one.
      if (!foundCategory) {
        const newItemInventoryCategory: ItemInventoryByCategory = {
          category: value.category ?? "",
        }
        // determine whether or not to add this item to a category-level list or a subcategory grouping
        if (value.subcategory) {
          newItemInventoryCategory.subcategories = [{ name: value.subcategory, items: [value] }]
        } else {
          newItemInventoryCategory.items = [value]
        }
        tempInventoryItems.push(newItemInventoryCategory)
      }
    })
    return tempInventoryItems
  }

  useEffect(() => {
    let tempInventoryItems: ItemInventoryByCategory[] = []

    if (searchString !== "") {
      inventoryItems.forEach((category) => {
        let tempCategory: ItemInventoryByCategory = {
          category: category.category,
        }
        let showCategory = false
        // IF ITEMS ARE GROUPED BY CATEGORY, NOT SUBCATEGORY
        if (category.items) {
          tempCategory.items = filterItems(category.items, searchString)
          if (tempCategory.items.length > 0) {
            showCategory = true
          }
        }
        // IF ITEMS ARE GROUPED BY SUBCATEGORY
        if (category.subcategories) {
          // filter out any subcategories with no matching items
          // (we need a deep copy to ensure no items are permanently filtered when search changes)
          const deepCopy: Subcategory[] = JSON.parse(JSON.stringify(category.subcategories))
          tempCategory.subcategories = deepCopy.filter((subcat) => {
            subcat.items = filterItems(subcat.items, searchString)
            return subcat.items.length > 0
          })
          // if a there is subcategory with an item that matches, show it in the search results
          if (tempCategory.subcategories.length > 0) {
            showCategory = true
          }
        }
        // show the filtered items in this category, if any exist
        if (showCategory) {
          tempInventoryItems.push(tempCategory)
        }
      })
      setFilteredInventory([...tempInventoryItems])
    } else {
      tempInventoryItems = getInventoryItems()
      setFilteredInventory(tempInventoryItems)
    }
  }, [searchString])

  // Helper method, filters items based on a search query
  function filterItems(items: InventoryItem[], searchString: string) {
    return [
      ...items.filter((item) => {
        const name = item?.name?.toLowerCase() ?? ""
        const variation = item?.variation?.toLowerCase() ?? ""
        const category = item?.category?.toLowerCase() ?? ""
        const lowerCase = searchString.toLowerCase()
        return (
          name.includes(lowerCase) || category.includes(lowerCase) || variation.includes(lowerCase)
        )
      }),
    ]
  }

  useEffect(() => {
    // if the route has a trailing slash, remove it
    if (location.pathname.charAt(location.pathname.length - 1) === "/") {
      navigate("/inventory")
    }

    if (invData && typeof invData !== "undefined") {
      const tempInventoryItems = getInventoryItems()
      setInventoryItems(tempInventoryItems)
      setFilteredInventory(tempInventoryItems)
    }
  }, [invData])

  // BEGIN Load orders in progress on component mount ------------------------------------//

  const [ordersInProgress, setOrdersInProgress] = useState(new Array<AggregateOrderInfo>())
  const [areOrdersLoading, setAreOrdersLoading] = useState(false)
  const userID = useAppSelector((state) => state.user).userObj?.userId
  const agentID = useAppSelector((state) => state.user).userType?.agentId

  useEffect(() => {
    // display loading animation while orders are loading
    setAreOrdersLoading(true)
    if (userID && agentID && token) {
      OrderService.getOrdersByUser(userID, token)
        .then((aggregateOrderInfoList) => {
          const filtered = aggregateOrderInfoList.filter((orderAgg: AggregateOrderInfo) => {
            return (
              orderAgg.status == null ||
              (orderAgg.status.status !== OrderStatusEnum.Cancelled &&
                orderAgg.status.status !== OrderStatusEnum.Delivered &&
                orderAgg.status.status !== OrderStatusEnum.Rejected)
            )
          })
          setOrdersInProgress(filtered)
        })
        .finally(() => {
          // turn off loading animation
          setAreOrdersLoading(false)
        })
    }
  }, [userID, token])

  // END Load orders in progress on component mount ------------------------------------//

  // BEGIN FCM token logic -------------------------------------------------------------//

  const fcmToken: string | null = useAppSelector((state: any) => state.user).fcmToken
  const userObj = useAppSelector((state) => state.user).userObj
  const fcmTokensInUserRecord = userObj?.fcmTokens ?? []
  const auth0Obj = useAppSelector((state) => state.user).auth0Obj

  /**
   * Determine if this device's Firebase Cloud Messaging token is stored in a user record
   * @param userRecord from Postgres
   */
  function isFCMTokenIsStored(): boolean {
    return Boolean(fcmToken) && (fcmTokensInUserRecord?.includes(fcmToken ?? "") ?? false)
  }

  useEffect(() => {
    if (!fcmToken || !userObj || !auth0Obj) {
      return
    }
    const user = { ...userObj }
    if (!isFCMTokenIsStored()) {
      console.log("token is not stored in user record!")
      const tokens = [...fcmTokensInUserRecord]
      tokens.push(fcmToken)
      user.fcmTokens = tokens
      UserService.updateUser(user, user.auth0Id, token)
    }
    // Subscribe users to notification topics based on their role (such as "Admin")
    PushNotificationService.subscribeToTopic(auth0Obj["web-app-roles"] ?? [], fcmToken, token)
    dispatch(setUser(user))
  }, [fcmToken, fcmTokensInUserRecord])

  // END FCM token logic -------------------------------------------------------------//

  const promptToStartOrder = () => {
    return (
      <>
        {!areOrdersLoading && ordersInProgress.length > 0 && (
          <OrdersInProgress aggregateOrderInfoList={ordersInProgress} inventory={invData} />
        )}
        {!areOrdersLoading && ordersInProgress.length === 0 && <OrdersInProgressEmptyState />}
      </>
    )
  }

  const displayInventory = () => {
    let inventoryJsx = (
      <InventoryGuts setSearchString={setSearchString} inventoryItems={filteredInventory} />
    )
    return inventoryJsx
  }

  return (
    <PageContainer isLoading={isFetching || areOrdersLoading}>
      {currentOrderInfo.jobCode === "" ? promptToStartOrder() : displayInventory()}
    </PageContainer>
  )
}
