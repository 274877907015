import axios from "axios"
import { User } from "../features/user/userSlice"

const apiUrl = process.env.REACT_APP_API_HOST
const authAudience = process.env.REACT_APP_AUTH0_AUDIENCE

export interface CreateUserPayload extends User {
  inviteCode: string
}

// get the user
const getUser = async (auth0Id: string, token: string) => {
  console.log(authAudience)
  return await axios.get(`${apiUrl}/users/${auth0Id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const getUserByPK = async (userId: string, token: string) => {
  return await axios.get(`${apiUrl}/user-by-pk/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const createUser = async (payload: CreateUserPayload, token: string) => {
  const res = await axios.post(
    `${apiUrl}/users`,
    {
      userId: payload.userId,
      auth0Id: payload.auth0Id,
      lastName: payload.lastName,
      firstName: payload.firstName,
      email: payload.email,
      phone: payload.phone,
      picture: payload.picture,
      createdAt: payload.createdAt,
      updatedAt: payload.updatedAt,
      deletedAt: payload.deletedAt,
      fcmTokens: payload.fcmTokens,
      inviteCode: payload.inviteCode,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  return res
}

const updateUser = async (payload: User, auth0Id: string, token: string) => {
  const res = await axios.put(
    `${apiUrl}/users/${auth0Id}`,
    {
      userId: payload.userId,
      auth0Id: payload.auth0Id,
      lastName: payload.lastName,
      firstName: payload.firstName,
      email: payload.email,
      phone: payload.phone,
      picture: payload.picture,
      createdAt: payload.createdAt,
      updatedAt: payload.updatedAt,
      deletedAt: payload.deletedAt,
      fcmTokens: payload.fcmTokens,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  return res
}

export const UserService = { getUser, createUser, updateUser, getUserByPK }
