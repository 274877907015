import { Flex, Box, Button, Spacer, Image, useDisclosure } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { Heading } from "../../../components/Heading.component"
import { Text } from "../../../components/Text.component"
import { useEffect, useState } from "react"
import { InventoryItem, SingleItemProps } from "../Inventory.types"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../app/hooks"
import { addToCart, updateCart } from "../../../features/cart/cartSlice"
import { CartItem } from "../../Cart/Cart.types"
import { ItemAddedModal } from "./ItemAddedModal.component"
import { useNavigate } from "react-router-dom"
import { formatMoney } from "../../../helpers"

export const SingleItem = (props: SingleItemProps) => {
  const dispatch = useAppDispatch()
  const cart = useSelector((state: any) => state.cart.cartItems)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const [quantity, setQuantity] = useState(1)
  const [correlatedItems, setCorrelatedItems] = useState<InventoryItem[]>([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const tempCorrelatedItems = props.correlatedItems.filter((correlatedItem) => {
      return props.item !== correlatedItem
    })
    setCorrelatedItems(tempCorrelatedItems)
  }, [props.item])

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const increaseQuantity = () => {
    setQuantity(quantity + 1)
  }

  const addMainItemToCart = () => {
    if (!isItemInCart(props.item)) {
      const itemToAdd: CartItem = {
        ...props.item,
        count: quantity,
      }
      dispatch(addToCart(itemToAdd))
      onOpen()
    } else {
      updateItemQuantityInCart(props.item, quantity)
    }
  }

  const quickAddItemToCart = (item: InventoryItem) => {
    if (!isItemInCart(item)) {
      const itemToAdd: CartItem = {
        ...item,
        count: 1,
      }
      dispatch(addToCart(itemToAdd))
      onOpen()
    } else {
      updateItemQuantityInCart(item, 1)
    }
  }

  const isItemInCart = (item: InventoryItem) => {
    return getItemInCart(item).length > 0
  }

  const getItemInCart = (item: InventoryItem) => {
    return cart.items.filter(
      (cartItem: CartItem) =>
        cartItem.itemId === item.itemId && cartItem.variation === item.variation,
    )
  }

  const updateItemQuantityInCart = (item: InventoryItem, amount: number) => {
    const itemToUpdate = { ...getItemInCart(item)[0] }
    itemToUpdate.count += amount
    dispatch(updateCart(itemToUpdate))
    onOpen()
  }

  const onContinueShopping = () => {
    onClose()
    navigate("/inventory")
  }

  const onViewCart = () => {
    onClose()
    navigate("/cart")
  }

  return (
    <>
      <Flex mt={"0.625rem"} ml={"1rem"} mr={"1rem"} direction={"column"}>
        <Box>
          <Button variant={"link"} onClick={() => props.onBackClick()}>
            <ArrowBackIcon /> Back
          </Button>
        </Box>
        <Flex flexDirection={"row"} justifyContent={"space-between"} pb="0.4rem" pt="1.6rem">
          <Heading variant={"h3"} pb="0">
            {`${props?.item?.name?.charAt(0)?.toUpperCase()}${props?.item?.name?.slice(1)}`}
          </Heading>
          <Heading type={"h3"} pb="0">
            ${formatMoney(props?.item?.price)}
          </Heading>
        </Flex>
        <Flex flexDirection={"row"} justifyContent={"space-between"} pb={"1.6rem"}>
          <Text type={"b1"}>
            {`${props?.item?.variation?.charAt(0)?.toUpperCase()}${props?.item?.variation?.slice(
              1,
            )}`}
          </Text>
          <Text type={"b1"}>/{props?.item?.unitOfMeasure?.toLowerCase()}</Text>
        </Flex>
        <Image
          src={props?.item?.imageURL}
          width={"100%"}
          maxHeight={"50vh"}
          objectFit="cover"
          alignSelf={"center"}
        />
        {props?.item?.description && <Text py={"0.5rem"}>{props?.item?.description}</Text>}
        <Flex
          flexDirection={"row"}
          mt="1rem"
          pb={"1rem"}
          borderBottom={"1px"}
          borderColor={"gray.300"}
        >
          <Button variant={"secondary"} onClick={decreaseQuantity}>
            -
          </Button>
          <Box ml={"10px"} mr={"10px"} mt={"5px"}>
            <Text type="b1">{quantity}</Text>
          </Box>
          <Button variant={"secondary"} onClick={increaseQuantity}>
            +
          </Button>
          <Spacer />
          <Button variant={"primary"} onClick={addMainItemToCart}>
            Add to Cart
          </Button>
        </Flex>
        <Box>
          {correlatedItems.map((item) => {
            return (
              <Flex flexDirection={"row"} mt={"1rem"} key={item.itemId}>
                <Flex flexDirection={"row"}>
                  <Image src={item.imageURL} height={"4em"} width={"4em"} />
                  <Box
                    ml={"10px"}
                    onClick={() => props.navigateToSingleItem(item, props.correlatedItems)}
                  >
                    <Text type="b2Bold">
                      {`${item?.name?.charAt(0)?.toUpperCase()}${item?.name?.slice(1)}`}
                    </Text>
                    <Text type="b2">
                      {`${item?.variation?.charAt(0).toUpperCase()}${item?.variation?.slice(1)}`}
                    </Text>
                  </Box>
                </Flex>
                <Spacer />
                <Button variant={"primary"} size={"sm"} onClick={() => quickAddItemToCart(item)}>
                  Add to Cart
                </Button>
              </Flex>
            )
          })}
        </Box>
      </Flex>
      <ItemAddedModal
        isOpen={isOpen}
        onContinueShopping={onContinueShopping}
        onViewCart={onViewCart}
        cartSize={cart.items.length}
      />
    </>
  )
}
