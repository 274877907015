import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    chakra,
    InputProps as ChakraInputProps,
    Flex,
  } from "@chakra-ui/react"
  
  import { Text } from "../../../components/Text.component"
  
  export type ItemAddedModalProps = {
    isOpen: boolean
    onConfirmRemove: () => void
    onCancelRemove: () => void
  } & ChakraInputProps
  
  export const RemoveItemModal = chakra(
    ({ isOpen, onConfirmRemove, onCancelRemove }: ItemAddedModalProps) => {
      return (
        <>
          <Modal isOpen={isOpen} onClose={onCancelRemove}>
            <ModalOverlay />
            <ModalContent w="95%">
              <ModalBody mt={"0.8rem"}>
                <Text type={"b1"} textAlign={"center"}>
                  Are you sure you want to remove this item?
                </Text>
              </ModalBody>
              <ModalFooter justifyContent={"center"}>
                <Flex direction={"column"}>
                  <Button w="100%" onClick={onConfirmRemove} variant={"primary"}>
                    Confirm
                  </Button>
                  <Button w="100%" onClick={onCancelRemove} variant={"secondary"} mt={"8px"}>
                    Cancel
                  </Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )
    },
  )
  