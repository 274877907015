import { Flex, Box, Text, Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { AddIcon } from "@chakra-ui/icons"

export const OrdersInProgressEmptyState = () => {
  const navigate = useNavigate()

  return (
    <Flex
      flex="1"
      flexDir="column"
      backgroundColor={"gray.50"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
    >
      <Box mb="4rem">
        <Text pb="2.4rem" textAlign={"center"}>
          No orders in progress.
        </Text>
        <Button onClick={() => navigate("/startOrder")}>
          <AddIcon w={"1.333rem"} h={"1.333rem"} mr="1.133rem" />
          Start New Order
        </Button>
      </Box>
    </Flex>
  )
}
